<template>
  <div>
    <div 
      class="flex flex-col sm:flex-row gap-2 justify-center w-full max-w-3xl" 
    >
      <PrimeButton 
        v-if="!addManually"
        severity="success"
        label="Adicionar Manualmente"
        @click="toggleAddManually" 
      />
    </div>

    <div 
      v-if="addManually"
      class="mb-4 border-2 p-11 rounded-lg" 
    >
      <div class="flex flex-col gap-8 justify-center">
        <div 
          v-if="recipientList.length > 0"
        >   
          <div class="card flex justify-center gap-3">
            <div class="flex items-center justify-center ">
              <i class="pi pi-file" />
            </div>
            <FloatLabel>
              <InputText 
                id="listName" 
                v-model="listName"
                @input="emitListName"
              />
              <label for="listName">Nome da lista (opcional)</label>
            </FloatLabel>
          </div>
        </div> 

        <div class="card flex justify-center gap-3">
          <div class="flex items-center justify-center ">
            <i class="pi pi-user" />
          </div>
          <FloatLabel>
            <InputText 
              id="clientName" 
              v-model="newClient.name"
              required 
            />
            <label for="clientName">Nome do Destinatário</label>
          </FloatLabel>
        </div>

        <div class="card flex justify-center gap-3">
          <div class="flex items-center justify-center ">
            <i class="pi pi-envelope" />
          </div>
          <FloatLabel>
            <InputText 
              id="clientEmail" 
              v-model="newClient.email"
              required 
            />
            <label for="clientEmail">Email do Destinatário</label>
          </FloatLabel>
        </div>

        <div class="flex flex-col sm:flex-row gap-2 justify-center w-full max-w-3xl">
          <PrimeButton
            label="Adicionar"
            severity="success"
            @click="addManualEmail"
          />

          <PrimeButton 
            v-if="recipientList.length > 0" 
            label="Disparar E-mails"
            severity="sucess"
            @click="emitDispararEmail" 
          />

          <PrimeButton  
            label="voltar"
            severity="warn"
            @click="cancelAddManually" 
          /> 

          <PrimeButton 
            v-if="recipientList.length > 0"
            v-tooltip="'Ajuda'"
            type="button"
            class="botaoDeAjuda"
            label="Ajuda"
            icon="pi pi-question-circle"
            icon-pos="right" 
            @click="visibleRight = true"      
          />
        </div>
      </div>
    </div>
    
    <div 
      v-if="recipientList.length > 0"
      class="flex flex-col mt-3 justify-center w-full border-2 " 
    >
      <ul class="flex flex-col w-full">
        <li 
          v-for="client in recipientList" 
          :key="client.id" 
          class="mb-1"
        >
          <div class="flex md:flex-row gap-2 p-2">
            <div class="flex-1">
              <p class="text-left break-words">
                <strong>Nome:</strong> {{ client.name }}
              </p>
              <p class="text-left break-words">
                <strong>Email:</strong> {{ client.email }}
              </p>
            </div>
            <div class="flex gap-2 justify-center ">
              <PrimeButton 
                icon="pi pi-pencil"
                severity="warn"
                @click="editClient(client)" 
              />
              <PrimeButton
                icon="pi pi-times"
                severity="danger" 
                @click="deleteClient(client)" 
              />
            </div>
          </div>
        </li>
      </ul>
    </div>

    <Dialog 
      v-model:visible="isEditDialogVisible" 
      pt:root:class="!border-0 "
      pt:mask:class="backdrop-blur-sm"
      header="Editando..." 
      :modal="true" 
      :closable="false"
    >
      <div class="flex flex-col px-8 gap-6 rounded-2xl">
        <div class="inline-flex flex-col gap-2">
          <label for="name">Nome</label>
          <InputText 
            id="name" 
            v-model="editClientData.name" 
          />
        </div>
        <div class="inline-flex flex-col gap-2">
          <label for="email">Email</label>
          <InputText 
            id="email" 
            v-model="editClientData.email" 
          />
        </div>
        <div class="flex items-center gap-4">
          <PrimeButton 
            label="cancelar"
            severity="danger"
            icon="pi pi-times" 
            @click="cancelEdit"
          />
          <PrimeButton 
            label="Salvar"
            severity="success"
            icon="pi pi-check" 
            @click="saveEdit"
          />
        </div>
      </div>
    </Dialog>
    <Drawer 
      v-model:visible="visibleRight"
      header="Precisando de ajuda?" 
      position="right"
    >
      <p> informações </p>
    </Drawer>
  </div>
</template>

<script setup>
  import { ref, reactive, defineEmits } from 'vue';
  import { useToast } from 'primevue/usetoast';
  import Dialog from 'primevue/dialog';
  import FloatLabel from 'primevue/floatlabel';
  import InputText from 'primevue/inputtext'; 
  import Drawer from 'primevue/drawer';

  const visibleRight=ref(false);
  const addManually = ref(false);
  const isEditDialogVisible = ref(false);

  const emit = defineEmits(['toggleAddManually', 'listNameChanged','updateRecipientList', 'DispararEmailManual']);

  const listName = ref('');
  const toast = useToast();
  const recipientList = ref([]);
  const editClientData = reactive({ id: null, name: '', email: '' });
  const newClient = reactive({
    name: '',
    email: ''
  });

  const editClient = (client) => {
    editClientData.id = client.id;
    editClientData.name = client.name;
    editClientData.email = client.email;
    isEditDialogVisible.value = true;
  };

  const saveEdit = () => {
    const { name, email } = editClientData;

    if (!name.trim()) {
      toast.add({ severity: 'warn', summary: 'Erro', detail: 'Por favor, insira um nome válido!', life: 3000 });
      return;
    }

    if (!isValidEmail(email)) {
      toast.add({ severity: 'warn', summary: 'Erro', detail: 'Por favor, insira um endereço de e-mail válido!', life: 3000 });
      return;
    }

    const client = recipientList.value.find(c => c.id === editClientData.id);
    if (client) {
      client.name = name.trim();
      client.email = email.trim();
      toast.add({ severity: 'success', summary: 'Sucesso', detail: 'Cliente atualizado com sucesso!', life: 3000 });
    }
    isEditDialogVisible.value = false;
    editClientData.id = null;
    editClientData.name = '';
    editClientData.email = '';
    emit('updateRecipientList', recipientList.value);
  };

  const cancelEdit = () => {
    isEditDialogVisible.value = false;
    editClientData.id = null;
    editClientData.name = '';
    editClientData.email = '';
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const toggleAddManually = () => {
    addManually.value = !addManually.value;
    newClient.name = '';
    newClient.email = '';
    emit('toggleAddManually');
  };

  const addManualEmail = async () => {
    const name = newClient.name.trim();
    const email = newClient.email.trim();

    let errorMessage = '';

    if (name === '') {
      errorMessage = 'Por favor, preencha o campo Nome.';
    } else if (email === '') {
      errorMessage = 'Por favor, preencha o campo Email.';
    } else if (!isValidEmail(email)) {
      errorMessage = 'Por favor, insira um endereço de email válido.';
    }

    if (errorMessage !== '') {
      toast.add({ severity: 'error', summary: 'Erro', detail: errorMessage, life: 3000 });
      return;
    }

    recipientList.value.push({
      id: Date.now(),
      name: newClient.name,
      email: newClient.email
    });
    newClient.name = '';
    newClient.email = '';

    emit('updateRecipientList', recipientList.value);
  };

  const cancelAddManually = () => {
    newClient.name = '';
    newClient.email = '';
    recipientList.value = []
    addManually.value = false;
    emit('toggleAddManually');
  };

  const emitListName = () => {
    if (listName.value.trim() !== '') {
      emit('listNameChanged', listName.value.trim());
    } else {
      emit('listNameChanged', listName.value);
    }
  };

  const deleteClient = (client) => {
    const index = recipientList.value.findIndex(item => item.id === client.id);
    if (index !== -1) {
      recipientList.value.splice(index, 1);
      emit('updateRecipientList', recipientList.value);
      if (recipientList.value.length === 0) {
        addManually.value = false;
        toast.add({
          severity: 'info',
          summary: 'Informação',
          detail: 'Todos os clientes foram removidos!',
          life: 3000
        });
        emit('toggleAddManually');
      } else {
        toast.add({
          severity: 'warn',
          summary: 'Atenção',
          detail: 'Cliente deletado com sucesso!',
          life: 3000
        });
      }
    }
  };

  const emitDispararEmail = () =>{
    emit("DispararEmailManual")
  }

</script>

<style scoped>
  .dark-theme p{
    color: white;
  }

  .light-theme .botaoDeAjuda,
  .dark-theme .botaoDeAjuda {
    background-color: rgb(192, 192, 192);
    border-color:rgb(192, 192, 192);
  }

  .light-theme .botaoDeAjuda:hover,
  .dark-theme .botaoDeAjuda:hover{
    background-color: rgba(114, 114, 114, 0.685);
    border-color:rgba(114, 114, 114, 0.685);
  }
</style>