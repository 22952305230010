<template>
  <div class="flex flex-col gap-2">
    <h2>Disparar Mensagens em Massa</h2>
    <p v-if="sending">
      Enviando mensagens...
    </p>
    <p v-if="!sending && successMessage">
      {{ successMessage }}
    </p>
    <p v-if="!sending && errorMessage">
      {{ errorMessage }}
    </p>
    <div class="flex items-center justify-center gap-2">
      <PrimeButton
        label="Enviar"
        severity="success"
        :disabled="sending"
        @click="showTemplate('send')"
      />
      <PrimeButton
        label="Cancelar Tudo"
        severity="danger"
        :disabled="sending"
        @click="showTemplate('cancel')"
      />
    </div>
    <div class="card flex justify-center teste">
      <Toast 
        position="center" 
        group="bc" 
        @close="onClose"
      >
        <template #message="slotProps">
          <div class="flex flex-col items-start flex-auto gap-2">
            <div class="flex items-center gap-2">
              <Avatar 
                image="/imgs/home/larzinhoMascote.png" 
                size="large" 
              />
              <span class="font-bold">Larzinho</span>
            </div>
            <div class="flex flex-col justify-center items-center w-full">
              <div 
                class="p-2" 
                v-html="slotProps.message.summary" 
              />
            </div>
            <div class="flex justify-center items-center gap-2 w-full">
              <PrimeButton 
                v-if="toastType === 'send'"
                label="Confirmar Envio!"
                severity="success"
                @click="sendMessages()"
              />
              <PrimeButton 
                v-if="toastType === 'send'"
                label="Cancelar Envio!"
                severity="danger"
                @click="onReply()"
              />
              <PrimeButton 
                v-if="toastType === 'cancel'"
                label="Confirmar Cancelamento"
                severity="danger"
                @click="confirmCancel()"
              />
              <PrimeButton 
                v-if="toastType === 'cancel'"
                label="Voltar"
                severity="warn"
                @click="onReply()"
              />
            </div>
          </div>
        </template>
      </Toast>
    </div>
  </div>
</template>

<script setup>
  import { ref, defineEmits, defineProps, toRefs } from 'vue';
  import { useToast } from 'primevue/usetoast';
  import Toast from 'primevue/toast';
  import Avatar from 'primevue/avatar';
  import PrimeButton from 'primevue/button';

  // State variables
  const visible = ref(false);
  const toast = useToast();
  const toastType = ref('');
  const sending = ref(false);
  const successMessage = ref('');
  const errorMessage = ref('');

  // Props
  const props = defineProps({
    confirmList: {
      type: Object,
      required: true,
    },
    confirmCampaign: {
      type: Object,
      required: true,
    },
  });

  const { confirmList, confirmCampaign } = toRefs(props);

  // Emits
  const emit = defineEmits(['disparadoComSucesso', 'send', 'cancel']);

  // Show template function
  const showTemplate = (type) => {
    console.log('showTemplate called with type:', type);
    console.log('Confirm Campaign:', confirmCampaign.value);

    if (!confirmCampaign.value) {
    console.warn('confirmCampaign is null or undefined');
    return;
  }

    toastType.value = type;
    if (!visible.value) {
      let summary = '';
      if (type === 'send') {
        summary = `Deseja disparar a campanha?<br>${confirmCampaign.value.titulo || confirmCampaign.value.nome}<br>Usando a Lista:<br><span style="color: black;"><strong>${confirmList.value.listaNome}</strong></span>`;
      } else if (type === 'cancel') {
        summary = `A campanha: ${confirmCampaign.value.titulo || confirmCampaign.value.nome}<br>Usando a lista:<br><span style="color: black;"><strong>${confirmList.value.listaNome}</strong></span><br>não serão enviadas!`;
      }
      console.log('Toast summary:', summary);
      toast.add({ severity: type === 'send' ? 'info' : 'warn', summary, group: 'bc' });
      visible.value = true;
    }
  };

  // Reply function
  const onReply = () => {
    console.log('onReply called');
    toast.removeGroup('bc');
    visible.value = false;
  };

  // Close function
  const onClose = () => {
    console.log('onClose called');
    visible.value = false;
  };

  // Send messages function
  async function sendMessages() {
    console.log('sendMessages called');
    sending.value = true;
    try {
      const response = await fetch(`${process.env.VUE_APP_BASE_URL}/whats/dispararCampanha`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          confirmList: confirmList.value,
          confirmCampaign: confirmCampaign.value
        })
      });

      console.log('Response:', response);

      if (response.ok) {
        successMessage.value = 'Campanha enviada com sucesso!';
        toast.add({ severity: 'success', summary: 'Sucesso', detail: 'Campanha enviada com sucesso!', life: 3000 });
        emit('disparadoComSucesso');
      } else {
        errorMessage.value = 'Erro ao enviar campanha. Tente novamente mais tarde.';
        toast.add({ severity: 'error', summary: 'Erro', detail: 'Erro ao enviar campanha. Tente novamente mais tarde.', life: 3000 });
      }
    } catch (error) {
      console.error('Error during sendMessages:', error);
      errorMessage.value = 'Erro ao enviar campanha. Tente novamente mais tarde.';
      toast.add({ severity: 'error', summary: 'Erro', detail: 'Erro ao enviar campanha. Tente novamente mais tarde.', life: 3000 });
    } finally {
      sending.value = false;
    }

    emit('send');
  }

  // Confirm cancel function
  function confirmCancel() {
    console.log('confirmCancel called');
    emit('cancel');
    toast.add({ severity: 'success', summary: 'Cancelado', detail: 'Disparo Cancelado', life: 3000 });
    visible.value = false;
  }
</script>
