<template>
  <div class="flex flex-col min-h-screen w-screen">
    <HeaderTemplate />
    <LoadingTemplate 
      @loading-complete="loadingCompleteHandler" 
    />

    <div 
      v-if="!loading"
      class="flex flex-col items-center justify-center flex-grow"
    >
      <AnalyticsHeader
        :months="months"
        :available-years="availableYears"
        :selected-month="selectedMonth"
        :selected-year="selectedYear"
        @month-change="onMonthChange"
        @year-change="onYearChange"
      />
      <div 
        v-if="!loading" 
        class="flex flex-col items-center w-full"
      >
        <p 
          v-if="filteredMessages.length === 0" 
          class="notPromotion p-4 text-center font-bold"
        >
          Nenhuma promoção encontrada para o mês selecionado.
        </p>
        <TemplateList
          v-if="filteredMessages.length > 0"
          :filtered-messages="filteredMessages"
          :selected-template="selectedTemplate"
          @select-template="selectTemplate"
        />
        <TemplateDetails
          v-if="selectedTemplate"
          :selected-template="selectedTemplate"
          :show-emails-opened="showEmailsOpened"
          :show-clicks-details="showClicksDetails"
          :expanded-categories="expandedCategories"
          :filtered-emails-opened="filteredEmailsOpened"
          :email-clicks-by-category="emailClicksByCategory"
          @toggle-emails-opened="toggleEmailsOpened"
          @toggle-clicks-details="toggleClicksDetails"
          @toggle-category="toggleCategory"
          @close="closeTemplateDetails"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
  import { ref, onMounted, onBeforeUnmount, computed } from 'vue';
  import AnalyticsHeader from '../../components/emails/analytics/AnalyticsHeader.vue';
  import TemplateList from '../../components/emails/analytics/TemplateList.vue';
  import TemplateDetails from '../../components/emails/analytics/TemplateDetails.vue';
  import socket, { onEmailTracked } from '../../socket';
  import { useLoading } from '../../mixin/loadingMixin';

  const { loading, loadingCompleteHandler } = useLoading();


  const messages = ref([]);
  const filteredMessages = ref([]);
  const selectedTemplate = ref(null);
  const showEmailsOpened = ref(false);
  const showClicksDetails = ref(false);
  const expandedCategories = ref({
    promocao: false,
    site: false,
    appIos: false,
    appAndroid: false,
    facebook: false,
    insta: false,
    youtube: false
  });
  const selectedYear = ref(new Date().getFullYear());
  const selectedMonth = ref(new Date().getMonth() + 1);

  const months = [
    "Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho",
    "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"
  ];

  const loadData = async () => {
  
    try {
      const response = await fetch(`${process.env.VUE_APP_BASE_URL}/email/rastreios?month=${selectedMonth.value}&year=${selectedYear.value}`);
      if (!response.ok) {
        throw new Error('A resposta da rede não foi ok');
      }
      const data = await response.json();
      messages.value = data;
      filterMessages();
    } catch (error) {
      console.error('Erro ao carregar dados:', error);
    } 
  };

  const connectToSocket = () => {
    onEmailTracked((message) => {
      console.log("Mensagem rastreada recebida:", message);
      const messageDate = new Date(message.date);
      if (messageDate.getFullYear() === selectedYear.value && (messageDate.getMonth() + 1) === selectedMonth.value) {
        messages.value.unshift(message);
        filterMessages();
      }
    });
  };

  const selectTemplate = (template) => {
    if (selectedTemplate.value && selectedTemplate.value._id === template._id) {
      selectedTemplate.value = null;
      showEmailsOpened.value = false;
      showClicksDetails.value = false;
      resetExpandedCategories();
    } else {
      selectedTemplate.value = template;
      showEmailsOpened.value = false;
      showClicksDetails.value = false;
      resetExpandedCategories();
    }
  };

  const toggleEmailsOpened = () => {
    showEmailsOpened.value = !showEmailsOpened.value;
  };

  const toggleClicksDetails = () => {
    showClicksDetails.value = !showClicksDetails.value;
  };

  const toggleCategory = (category) => {
    expandedCategories.value[category] = !expandedCategories.value[category];
  };

  const resetExpandedCategories = () => {
    expandedCategories.value = {
      promocao: false,
      site: false,
      appIos: false,
      appAndroid: false,
      facebook: false,
      insta: false,
      youtube: false
    };
  };

  const closeTemplateDetails = () => {
    selectedTemplate.value = null;
    showEmailsOpened.value = false;
    showClicksDetails.value = false;
    resetExpandedCategories();
  };

  const filterMessages = () => {
    filteredMessages.value = messages.value.filter(message => {
      const messageDate = new Date(message.date);
      return messageDate.getFullYear() === selectedYear.value && (messageDate.getMonth() + 1) === selectedMonth.value;
    });
  };

  const onMonthChange = async (month) => {
    selectedMonth.value = parseInt(month);
    await loadData();
  };

  const onYearChange = async (year) => {
    selectedYear.value = parseInt(year);
    await loadData();
  };

  const availableYears = computed(() => {
    const years = new Set(messages.value.map(message => new Date(message.date).getFullYear()));
    return Array.from(years).sort((a, b) => b - a);
  });

  const filteredEmailsOpened = computed(() => {
    if (!selectedTemplate.value) return [];
    return selectedTemplate.value.emailClicks.filter(click => click.opened);
  });

  const emailClicksByCategory = computed(() => {
    const categories = ['promocao', 'site', 'appIos', 'appAndroid', 'facebook', 'insta', 'youtube'];
    const clicks = { promocao: [], site: [], appIos: [], appAndroid: [], facebook: [], insta: [], youtube: [] };

    if (selectedTemplate.value) {
      selectedTemplate.value.emailClicks.forEach(click => {
        categories.forEach(category => {
          if (click[category] > 0) {
            clicks[category].push(click.email);
          }
        });
      });
    }
    return clicks;
  });

  onMounted(() => {
    loadData();
    connectToSocket();
  });

  onBeforeUnmount(() => {
    if (socket) {
      socket.disconnect();
    }
  });
</script>

<style>
  .light-theme .notPromotion{
    color:red ;
  }

  .dark-theme .notPromotion{
    color:red ;
  }
</style>