<template>
  <header 
    class="p-1"
  >
    <div 
      class="gap-2 hidden desktop:flex desktop:flex-wrap desktop:justify-center desktop:p-2 desktop:items-center"
    >
      <div
        :class="props.currentStep === 1 ? 'fade-in' : ''"
        class="gap-2 hidden desktop:flex desktop:flex-wrap desktop:justify-center desktop:items-center"
      >
        <nav class="mb-2 desktop:mb-0 navBg">
          <router-link
            :to="{ name: 'home' }"
            class="rounded cursor-pointer "
          >
            <p class="p-2 w-full">
              Inicio
            </p>
          </router-link>
        </nav>

        <nav 
          v-if="userData.permission === 5" 
          class="mb-2 desktop:mb-0 navBg"
        >
          <router-link
            :to="{ name: 'UsuarioPagina' }"
            class="rounded cursor-pointer "
          >
            <p class="p-2 w-full">
              Usuários
            </p>
          </router-link>
        </nav>

        <nav 
          v-if="userData.permission >= 3" 
          class="mb-2 desktop:mb-0 navBg"
        >
          <router-link 
             
            :to="{ name: 'iframes' }" 
            class=" rounded cursor-pointer "
          >
            <p class="p-2 w-full">
              Iframes
            </p>
          </router-link>
        </nav>

        <nav 
          v-if="userData.permission === 3 || userData.permission === 5"  
          class="relative group mb-0 navBg"
        >
          <div class="rounded cursor-pointer  ">
            <router-link 
              :to="{ name: 'emailMarketing' }"
            >
              <p class="p-2 w-full">
                Email Marketing
              </p>
            </router-link>

            <div  
              v-if="userData.permission === 3 || userData.permission === 5"  
              class="desenho desenhoBackground absolute hidden top-10 -left-3 w-40 transition duration-300 group-hover:block text-center"
            >
              <router-link 
            
                :to="{ name: 'analyticsMarketing' }"
              >
                <p class="p-2 w-full">
                  Analytics Emails
                </p>
              </router-link>
            </div>
          </div>
        </nav>

        <nav 
          v-if="userData.permission === 2 || userData.permission === 5"  
          class="relative group mb-2 desktop:mb-0 navBg"
        >
          <div class="rounded cursor-pointer  ">
            <router-link 
             
              :to="{ name: 'EmailCrediarioPage' }"
            >
              <p class="p-2 w-full">
                Email Crediario
              </p>
            </router-link>
          </div>

          <div  
            v-if="userData.permission === 2 || userData.permission === 5"  
            class="desenho desenhoBackground absolute hidden top-10 -left-3 w-40 transition duration-300 group-hover:block text-center"
          >
            <router-link 
             
              :to="{ name: 'AnalyticsCrediario' }"
            >
              <p class="p-2 w-full">
                Analytics Emails
              </p>
            </router-link>
          </div>
        </nav>

        <nav 
          v-if="userData.permission === 5" 
          class="relative group mb-2 desktop:mb-0 navBg" 
        >
          <p class="p-2 rounded cursor-pointer ">
            WhatsApp
          </p>

          <div class="desenho desenhoBackground absolute hidden shadow top-10 -left-8 w-40 transition duration-300 group-hover:block text-center">
            <router-link 
              v-if="userData.permission === 2 || userData.permission === 5" 
              :to="{ name: 'WhatsMarketingPage' }"
            >
              <p class="p-2 w-full">
                Promoções
              </p>
            </router-link>
          </div>
        </nav>
      </div>
     
      <div   
        :class="props.currentStep === 2 ? 'fade-in1' : ''"
        class="flex desktop:justify-end desktop:absolute desktop:right-5 desktop:items-center desktop:pl-4 laptop:relative laptop:justify-center laptop:pl-8 laptop:flex laptop:items-end laptop:gap-1 laptop:-mt-2 tablet:-mt-2"
      >
        <div 
          class="flex gap-2 items-center cursor-pointer"
          @click="toggleOverlay"
        >
          <div class="flex items-center">
            <Avatar 
              :icon="avatarIcon" 
              :image="avatarImage" 
              shape="circle" 
              size="large"
              style="background-color: #ece9fc; color: #2a1261"
            />
          </div>
          <div class="flex flex-col">
            <span class="font-bold leading-tight user">Olá, {{ userData.name }}</span>
            <span class="text leading-tight admin">{{ getUserPermission(userData.permission) }}</span>
          </div>
          <div class="flex items-center">
            <i class="pi pi-angle-down colorIcon" />
          </div>
        </div>
      </div>
    </div>
  
    <Menu 
      v-if="isOverlayVisible" 
      class="menu-container" 
      :model="menuItems"
    >
      <template
        #item="slotProps"
      >
        <div 
          v-if="slotProps.item.label === 'Tema Escuro'" 
          class="flex align-items-center justify-between toggle p-2"
        >    
          <p>
            {{ slotProps.item.label }}
          </p>  
          <ToggleSwitch 
            v-model="checked"
            @click="toggleTheme"
          />
        </div>
        <div 
          v-else 
          class="cursor-pointer p-2 select gap-2 "
          @click.stop="slotProps.item.command && slotProps.item.command()"
        >
          <i :class="slotProps.item.icon" />
          {{ slotProps.item.label }}
        </div>
      </template>
    </Menu>
  </header>
</template>

<script setup>
  import { ref, defineProps, watch} from 'vue';
  import { useUserData } from '../../composables/useUserData';
  import { useRouter } from 'vue-router' 
  import { toggleTheme, currentTheme } from '../../theme';
  import Menu from 'primevue/menu';
  import Avatar from 'primevue/avatar'; 
  import ToggleSwitch from 'primevue/toggleswitch';
  

  const router = useRouter() 
  const { userData } = useUserData();
  const isOverlayVisible = ref(false); 
  const imageUrl = '' 
  const avatarImage = imageUrl || ''
  const avatarIcon = imageUrl ? '' : 'pi pi-user'
  const checked = ref(false);
  
  const props = defineProps({
    currentStep: {
      type: Number,
      default:null
    },
  })

  const toggleOverlay = () => {
    isOverlayVisible.value = !isOverlayVisible.value;
  };
  
  function deleteCookie(name) {
    document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
  }

  const logout = () => {
    if(currentTheme.value === "dark-theme"){
      toggleTheme()
    }
    deleteCookie('token')  
    router.replace({ name: 'login' }) 
  }

  const getUserPermission = (permission) => {
    switch (permission) {
      case 1:
        return 'Admin Crediário';
      case 2:
        return 'Crediário';
      case 3:
        return 'Marketing';
      case 4:
        return 'Aux. de Marketing';
      case 5:
        return 'Admin Geral';
      default:
        return 'Unknown';
    }
  };

  watch(
    () => props.currentStep,
    (newValue) => {
      if ([1,3].includes(newValue)) {
        isOverlayVisible.value = false;
      }
    }
  );

  const menuItems = ref([
    {
      label: 'Tema Escuro',
      icon: 'pi pi-moon',
    },
   /*  {
      label: 'Configurações',
      icon: 'pi pi-cog',
      shortcut: '⌘+O',
      command: () => {
        console.log("Configurações clicadas");
      }
    }, */
    {
      label: 'Deslogar',
      icon: 'pi pi-sign-out',
      shortcut: '⌘+Q',
      command: () => {
        if(props.currentStep != 2){
          logout()
        }
      }
    },
  ]);
</script>

<style scoped>

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
 
  .light-theme .fade-in1,
  .light-theme .fade-in,
  .dark-theme .fade-in1,
  .dark-theme .fade-in1 {
    background-color: white;
    pointer-events: none; 
    z-index: 1000;
    animation: fadeIn 3.5s cubic-bezier(0.25, 0.1, 0.25, 1.0);
  }

  .dark-theme .fade-in1,
  .light-theme .fade-in1{
    pointer-events: visible; 
    animation: fadeIn 1.5s cubic-bezier(0.25, 0.1, 0.25, 1.0);
  }

  .dark-theme .fade-in1,
  .dark-theme .fade-in {
    background-color: rgba(0, 0, 0, 0);
  }

  .light-theme .colorIcon{
    color: black;
  }

  .light-theme .admin{
    color: rgba(15, 15, 15, 0.671);
  }

  .light-theme .toggle{
    background-color: white;
  }
  
  .light-theme .select{
    color: rgb(5, 5, 5);
  }

  .light-theme .select:hover{
    background-color: #01A13E;
    color: white;
  }

  .dark-theme .colorIcon{
    color: rgb(255, 255, 255);
  }

  .dark-theme .admin{
    color: rgba(211, 210, 210, 0.671);
  }

  .dark-theme .user{
    color: rgba(255, 255, 255, 0.671);
  }

  .dark-theme .toggle{
    background-color: black;
  }

  .dark-theme .select,
  .dark-theme p{
    color: rgb(255, 255, 255);
  }

  .dark-theme .select:hover{
    background-color: #015220;
    color: white;
  }

  .light-theme nav .desenhoBackground{
    background-color: white;
    border-radius: 5px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.4);
    z-index: 1;
  }

  .light-theme nav .desenhoBackground:hover{
    border: 2px solid white;
  }

  .light-theme nav .desenhoBackground p{
    color:#038D38;
    font-weight: lighter;
  }

  .light-theme nav .desenhoBackground p:hover{
    color: white;
    font-weight: lighter;
    background-color: #038D38; 
  }

  .dark-theme nav .desenhoBackground{
    z-index: 1;
    box-shadow: 0px 2px 8px rgb(0, 0, 0);
    background-color: white;
    border-radius: 5px;    
  }

  .dark-theme nav .desenhoBackground:hover{
    border: 2px solid white;
  }

  .dark-theme nav .desenhoBackground p{
    font-weight: lighter;
    color:#015220;
  }

  .dark-theme nav .desenhoBackground p:hover{
    color: white;
    background-color: #015220;
  }

  .dark-theme .desenho::before,
  .light-theme .desenho::before {
    content: '';
    position: absolute;
    top: -2%; 
    left: 50%;
    transform: translateX(-50%);
    border-width: 10px 10px 0 10px; 
    border-style: solid;
    border-color: red transparent transparent transparent; 
  }

  .dark-theme .navBg:hover,
  .light-theme .navBg:hover{
    background-color: rgba(255, 255, 255, 0.281);
    border-radius: 5px;
  }

</style>