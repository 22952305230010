<template>
  <div>
    <div v-if="!recipientList.length && (!fileData || fileData.length === 0)">
      <div class="flex gap-3">
        <input 
          id="fileInput" 
          type="file" 
          accept=".txt" 
          style="display: none;" 
          @change="handleFileUpload"
        >
        <div>
          <PrimeButton 
            label="Carregar destinatarios"
            severity="info"
            @click="openFileInput"
          />
        </div>
        <div>
          <PrimeButton 
            label="Voltar"
            severity="warn"
            @click="voltarAddOuInput"
          />
        </div>
      </div>
    </div>

    <div 
      v-if="!recipientList.length && fileData && fileData.length > 0" 
      class="border rounded p-4 overflow-hidden"
    >
      <div class="flex flex-col gap-2 justify-center my-2">
        <div class="card flex justify-center gap-2">
          <div class="flex items-center justify-center">
            <i class="pi pi-file" />
          </div>
          <FloatLabel>
            <InputText 
              id="listName" 
              v-model="listName"
              @input="emitListName"
            />
            <label for="listName">Nome da lista (opcional)</label>
          </FloatLabel>
        </div>
        <div class="flex gap-2 justify-center">
          <PrimeButton 
            label="Disparar E-mails"
            severity="success"
            @click="emitDispararEmail"
          />
          <PrimeButton 
            label="Voltar"
            severity="warn"
            @click="cancelAddManuallyOrInput"
          />
          <PrimeButton 
            v-tooltip="'Ajuda'"
            type="button"
            severity="help"
            label="Ajuda"
            icon="pi pi-question-circle"
            icon-pos="right"
            @click="visibleRight = true"
          />
        </div>
      </div>
      <div class="items-center flex flex-col gap-1 overflow-hidden">
        <h3 class="text-green-700 font-bold text-xl">
          Destinatários carregados: ({{ fileData.length }})
        </h3>
        <ul class="text-left">
          <li 
            v-for="(entry, index) in fileData" 
            :key="index" 
            class="mb-1"
          >
            <span class="block">Nome: {{ entry.name }} <br> Email: {{ entry.email }}</span>
          </li>
        </ul>
      </div>
    </div>
    <Drawer 
      v-model:visible="visibleRight"
      header="Precisando de ajuda?" 
      position="right"
    >
      <p>Informações</p>
    </Drawer>
  </div>
</template>

<script setup>
  import { ref, defineEmits } from 'vue';
  import FloatLabel from 'primevue/floatlabel';
  import InputText from 'primevue/inputtext'; 
  import Drawer from 'primevue/drawer';

  const visibleRight=ref(false);
  const emit = defineEmits(['file-data-updated', 'listNameChanged', 'voltarAddOuInputs', 'voltarCriarOuBuscar','DispararEmailManual']);

  const recipientList = ref([]);
  const fileData = ref([]);
  const listName = ref('');

  function handleFileUpload(event) {
    const file = event.target.files[0];
    if (!file) return;
    const reader = new FileReader();
    reader.onload = () => {
      const content = reader.result;
      const entries = content.split('\n').map(line => {
        const [name, email] = line.split('|').map(item => item.trim());
        return { name, email };
      });
      fileData.value = entries;
      emit('file-data-updated', entries);
    };
    reader.readAsText(file);
  }

   function voltarAddOuInput() {
    emit('voltarCriarOuBuscar')
  }

  function cancelAddManuallyOrInput(){
    recipientList.value = 0;
    fileData.value = [];
    listName.value = [];
    emit("voltarAddOuInputs")
  }

  function openFileInput() {
    document.getElementById('fileInput').click();
  }

  function emitListName() {
    if (listName.value.trim() !== '') {
      emit('listNameChanged', listName.value.trim());
    } else {
      emit('listNameChanged', listName.value);
    }
  }
  const emitDispararEmail = () =>{
    emit("DispararEmailManual",fileData)
  }
</script>

<style scoped>
  .dark-theme i,
  .dark-theme span{
    color: white;
  }

</style>