<template>
  <div class="flex flex-col items-center justify-center gap-2">
    <div class="text-center flex flex-col gap-2">
      <div class="flex flex-col gap-4">
        <FloatLabel>
          <InputText 
            id="list" 
            v-model="listName" 
            class="w-full" 
            @input="emitListName"
          />
          <label for="list">Nome da lista (Obrigatório)</label>
        </FloatLabel>

        <div class="flex gap-2 h-full w-full">
          <FileUpload 
            v-if="contacts.length === 0"
            mode="basic"
            accept=".txt"
            choose-label="Procurar Arquivo txt" 
            class="flex-grow h-full"
            @select="handleFileUpload"
          />

          <PrimeButton 
            v-if="contacts.length > 0 && listName !== ''"
            label="Enviar"
            severity="success"
            @click="enviarContatos"
          />

          <PrimeButton 
            v-if="contacts.length > 0"
            severity="danger"
            label="Resetar Arquivo"
            class="flex-grow h-full"
            @click="resetFileUpload"
          />

          <PrimeButton 
            v-if="contacts.length > 0"
            severity="info"
            label="Ver Contatos Carregados"
            class="flex-grow h-full"
            @click="showModal = true"
          />

          <PrimeButton 
            label="Voltar"
            severity="warn"
            class="flex-grow h-full" 
            @click="handleVoltarClick"
          />
        </div>
      </div>
    </div>

    <div 
      v-if="showModal" 
      class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
    >
      <Dialog 
        v-model:visible="showModal" 
        :modal="true" 
        :closable="false" 
        :draggable="false" 
        :resizable="false" 
        :breakpoints="{'960px': '75vw', '640px': '100vw'}" 
        :style="{ width: '50vw' }" 
        header="Contatos Carregados"
      >
        <template #header>
          <h3 class="text-lg font-bold mb-2">
            Contatos Carregados:
          </h3>
          <PrimeButton 
            icon="pi pi-times"
            class="p-button-rounded p-button-text p-button-danger" 
            aria-label="Fechar Modal" 
            style="position: absolute; top: 10px; right: 10px;" 
            @click="showModal = false" 
          />
        </template>

        <ul>
          <li 
            v-for="(contact, index) in contacts" 
            :key="index" 
            class="mb-1"
          >
            {{ contact }}
          </li>
        </ul>
      </Dialog>
    </div>
    <ConfirmDialog />
  </div>
</template>

<script setup>
  import { ref, defineEmits, getCurrentInstance } from 'vue';
  import FileUpload from 'primevue/fileupload';
  import FloatLabel from 'primevue/floatlabel';
  import InputText from 'primevue/inputtext';
  import PrimeButton from 'primevue/button';
  import ConfirmDialog from 'primevue/confirmdialog';
  import Dialog from 'primevue/dialog';

  const { proxy } = getCurrentInstance(); 
  const listName = ref('');
  const contacts = ref([]);
  const showModal = ref(false);
  const emit = defineEmits(['update:listName', 'contatos-enviados', 'voltarLista']);

  const emitListName = () => {
    emit('update:listName', listName.value);
  };

  const handleFileUpload = (event) => {
    const file = event.files[0];
    if (file) {
      parseFile(file);
    }
  };

  const parseFile = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const text = e.target.result;
      const lines = text.split('\n');
      const parsedContacts = lines.map(line => {
        const [name] = line.split(',');
        return name.trim();
      });
      contacts.value = parsedContacts;
    };
    reader.readAsText(file);
  };

  const enviarContatos = () => {
    const numeros = {
      recipients: contacts.value,
      listName: listName.value
    };

    fetch(`${process.env.VUE_APP_BASE_URL}/whats/createList`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(numeros),
    })
    .then((response) => response.json())
    .then((result) => {
      console.log('Success:', result);
      proxy.$toast.add({
        severity: 'success',
        summary: 'Sucesso',
        detail: 'Lista criada!',
        life: 3000
      });
      emit('contatos-enviados', numeros);
    })
    .catch((error) => {
      console.error('Error:', error);
      proxy.$toast.add({
        severity: 'error',
        summary: 'Erro',
        detail: 'Ocorreu um erro ao enviar os contatos.',
        life: 3000
      });
    });
  };

  const handleVoltarClick = () => {
    if (listName.value !== '' || contacts.value.length > 0) {
      proxy.$confirm.require({
        message: 'Você tem itens preenchido. Deseja realmente voltar?',
        header: 'Confirmação',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Sim',         
        rejectLabel: 'Não',         
        acceptClass: 'p-button-danger',
        rejectClass: 'p-button-secondary',
        accept: () => {
          proxy.$toast.add({ 
            severity: 'info',
            summary: 'Voltar',
            detail: 'Você optou por voltar.',
            life: 3000
          });
          voltar();
        },
        reject: () => {
        }
      });
    } else {
      voltar();
    }
  };

  const voltar = () => {
    emit('voltarLista');
  };

  const resetFileUpload = () => {
    contacts.value = [];
  };
</script>