<template>
  <div class="flex flex-col items-center justify-center gap-4">
    <div class="flex gap-2">
      <PrimeButton 
        v-if="isEditing" 
        severity="danger"
        label="Cancelar Edição"
        @click="cancelarEdicao"
      />

      <PrimeButton
        v-if="!isEditing" 
        severity="success"
        label="Confirmar lista"
        @click="enviarLista"
      />

      <PrimeButton 
        v-if="!isTodosOsContatos && !isEditing"
        severity="danger"
        label="Excluir Lista"
        @click="showConfirmDialog"
      />

      <PrimeButton 
        v-if="isEditing"
        severity="success"
        label="Salvar Alterações"
        @click="salvarAlteracoes"
      />

      <PrimeButton
        v-if="!isEditing && !isTodosOsContatos" 
        severity="secondary"
        label="Editar"
        @click="ativarEdicao"
      />

      <PrimeButton
        severity="warn"
        label="Selecionar outra lista"
        @click="voltar"
      />
    </div>

    <!-- Diálogo de Confirmação para Exclusão -->
    <Dialog
      v-model:visible="confirmDialogVisible"
      :closable="false"
      :style="{ width: '400px' }"
    >
      <div class="flex flex-col justify-center items-center text-center">
        <i class="pi pi-exclamation-triangle" style="font-size: 2rem; color: #ff9800;"></i>
        <p>Tem certeza que deseja excluir esta lista?</p>
      </div>
      <template #footer>
        <div class="flex justify-end gap-2">
          <PrimeButton
            label="Não"
            severity="secondary"
            @click="onReject"
          />
          <PrimeButton
            label="Sim"
            severity="danger"
            @click="onAccept"
          />
        </div>
      </template>
    </Dialog>

    <div class="border-2 w-full rounded-lg p-2 flex flex-col items-center gap-2" v-if="editableList">
      <p>Nome da lista: <span class="text-lg text-green-600 font-medium p-1rounded-md">{{ editableList.listaNome }}</span>
      </p>

      <ul >
        <li v-for="(contato, index) in editableList.contatos" :key="index" class="flex items-center gap-2  mb-1">
          <span v-if="!isEditing">{{ contato.telefone }}</span>
          <input
            v-else
            v-model="contato.telefone"
            type="text"
            @input="formatarTelefone(index)"
            maxlength="12"
            class="p-2 border-2"
          />

          <PrimeButton
            v-if="isEditing"
            icon="pi pi-times"
            severity="danger"
            @click="removerContato(index)"
          />
        </li>
      </ul>
      <ConfirmDialog />
      <div v-if="isEditing" class="flex flex-col gap-2">
        <!-- Campo de entrada para adicionar novos contatos -->
        <div class="flex items-center gap-2">
          <input
            v-model="novoContato.telefone"
            class="border rounded p-2"
            type="text"
            placeholder="Novo Telefone"
            @input="formatarNovoTelefone"
            maxlength="12"
          />
          <PrimeButton
            icon="pi pi-plus"
            severity="success"
            @click="adicionarNovoContato"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, defineProps, defineEmits, computed } from 'vue';
import { useToast } from 'primevue/usetoast';
import Dialog from 'primevue/dialog';
import { useConfirm } from 'primevue/useconfirm';
import ConfirmDialog from 'primevue/confirmdialog';


const confirm = useConfirm();
const props = defineProps({
  selectedList: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(['voltarBuscarListaNoBanco', 'confirmacaoDelista', 'listaExcluida']);

const editableList = ref({ ...props.selectedList });
const originalList = ref({ ...props.selectedList }); // Armazena a lista original
const isEditing = ref(false); // Indica se a edição está ativa
const novoContato = ref({ telefone: '' }); // Novo contato para ser adicionado

const toast = useToast(); 

watch(() => props.selectedList, (newVal) => {
  editableList.value = { ...newVal };
  originalList.value = { ...newVal };
}, { immediate: true });

const isTodosOsContatos = computed(() => editableList.value.listaNome === 'Todos Os Contatos');

const confirmDialogVisible = ref(false);

const showConfirmDialog = () => {
  confirmDialogVisible.value = true;
};

const onAccept = async () => {
  confirmDialogVisible.value = false;
  
  try {
    const response = await fetch(`${process.env.VUE_APP_BASE_URL}/whats/delete-list/${editableList.value._id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error('Erro ao excluir a lista do banco de dados');
    }

    // Emite o evento para o componente pai, se necessário
    emit('listaExcluida', editableList.value);

    toast.add({ severity: 'success', summary: 'Sucesso', detail: 'Lista excluída com sucesso.', life: 3000 });

   
  } catch (error) {
    console.error('Erro ao excluir a lista:', error);
    toast.add({ severity: 'error', summary: 'Erro', detail: 'Não foi possível excluir a lista. Tente novamente mais tarde.', life: 3000 });
  }
};


const onReject = () => {
  confirmDialogVisible.value = false;
};

const ativarEdicao = () => {
  // Clone profundo da lista original para edição
  editableList.value.contatos = JSON.parse(JSON.stringify(originalList.value.contatos));
  isEditing.value = true;
};

const cancelarEdicao = () => {
  // Restaura o estado original ao cancelar a edição
  editableList.value.contatos = JSON.parse(JSON.stringify(originalList.value.contatos));
  novoContato.value = { telefone: '' };
  isEditing.value = false;

  toast.add({ severity: 'info', summary: 'Edição Cancelada', detail: 'As alterações não foram salvas.', life: 3000 });
};

const salvarAlteracoes = async () => {
  try {
    // Atualiza a lista original com as alterações
    originalList.value.contatos = JSON.parse(JSON.stringify(editableList.value.contatos));
    novoContato.value = { telefone: '' };
    isEditing.value = false;

    console.log(editableList.value._id, "id da lista editada")

    // Envia a lista atualizada para a API
    const response = await fetch(`${process.env.VUE_APP_BASE_URL}/whats/update-list/${editableList.value._id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        listaNome: editableList.value.listaNome,
        contatos: editableList.value.contatos,
      }),
    });

    if (!response.ok) {
      throw new Error('Erro ao atualizar a lista no banco de dados');
    }

    toast.add({ severity: 'success', summary: 'Alterações Salvas', detail: 'As alterações foram salvas com sucesso.', life: 3000 });

  } catch (error) {
    console.error('Erro ao salvar alterações:', error);
    toast.add({ severity: 'error', summary: 'Erro', detail: 'Não foi possível salvar as alterações. Tente novamente mais tarde.', life: 3000 });
  }
};


const voltar = () => emit('voltarBuscarListaNoBanco');
const enviarLista = () => emit('confirmacaoDelista', editableList.value);

const isNumeroValido = (telefone) => {
  return /^\d{12}$/.test(String(telefone).trim());
};

const formatarTelefone = (index) => {
  if (editableList.value.contatos[index]) {
    let telefone = editableList.value.contatos[index].telefone.replace(/\D/g, '');
    editableList.value.contatos[index].telefone = telefone.slice(0, 12);
  }
};

const formatarNovoTelefone = () => {
  let telefone = novoContato.value.telefone.replace(/\D/g, '');
  novoContato.value.telefone = telefone.slice(0, 12);
};

const adicionarNovoContato = () => {
  if (isNumeroValido(novoContato.value.telefone)) {
    editableList.value.contatos.push({ telefone: novoContato.value.telefone });
    novoContato.value = { telefone: '' }; // Limpa o campo de entrada
  } else {
    toast.add({ severity: 'warn', summary: 'Número Inválido', detail: 'Digite um número válido.', life: 3000 });
  }
};

const removerContato = (index) => {
  confirm.require({
    message: 'Tem certeza que deseja remover este contato?',
    header: 'Excluir?',
    icon: 'pi pi-exclamation-triangle',
    acceptLabel: 'Sim',
    rejectLabel: 'Não',
    acceptClass: 'p-button-success',
    rejectClass: 'p-button-danger',
    accept: () => {
      if (index > -1 && editableList.value.contatos.length > index) {
        editableList.value.contatos.splice(index, 1); 
      }
      toast.add({ severity: 'success', summary: 'Contato Removido', detail: 'O contato foi removido com sucesso, não esqueça de salvar as alterações.', life: 3000 });
    },
  });







};
</script>
