<template>
  <div class="flex flex-col min-h-screen overflow-hidden">
    <HeaderTemplate />
    <LoadingTemplate 
      v-if="isLoading"
      @loading-complete="loadingCompleteHandler" 
    />

    <!-- Se o usuário não está logado e não está carregando, mostramos o QR code -->
    <div 
      v-if="!isLogged && !isLoading" 
      class="flex flex-col items-center justify-center flex-grow"
    >
      <!-- Mensagem para escanear o QR Code -->
      <h2 
        v-if="qrCodeUrl" 
        class="p-4 text-center font-bold typewriter"
      >
        {{ messageQr }}
      </h2>

      <!-- Exibe o QR Code se ele estiver disponível -->
      <div 
        class="text-center transform transition-all duration-300 hover:scale-105 focus-within:scale-105 animate-fadeIn"
      >
        <img 
          v-if="qrCodeUrl" 
          :src="qrCodeUrl" 
          alt="QR Code" 
          class="max-w-full h-auto rounded transition-opacity duration-700" 
          @load="fadeIn" 
        >

        <!-- Mostra mensagem de erro caso tenha ocorrido um problema -->
        <div v-else-if="error">
          <p>{{ errorMessage }}</p>
        </div>

        <!-- Mostra um spinner enquanto o QR Code está carregando -->
        <div 
          v-else 
          class="flex flex-col items-center"
        >
          <div class="spinner" />
        </div>
      </div>
    </div>

    <!-- Conteúdo principal quando o usuário está logado -->
    <div 
      v-if="isLogged" 
      class="flex flex-col flex-grow overflow-hidden relative"
    >
      <button 
        class="btn-toggle-aside p-2 justify-center text-center items-center" 
        :class="{ showAside }" 
        @click="toggleAside"
      >
        <span 
          :class="{ 'hidden': showAside, 'block': !showAside }" 
          class="transition-opacity"
        >
          Mostrar opções
        </span>
        <span 
          :class="{ 'hidden': !showAside, 'block': showAside }" 
          class="transition-opacity"
        >
          Ocultar opções
        </span>
      </button>

      <div class="flex flex-grow overflow-hidden">
        <div 
          v-if="showAside" 
          class="flex-col"
        >
          <aside 
            v-show="showAside"
            class="dashboard p-4 flex flex-col w-full h-full overflow-auto" 
          >
            <section class="mb-4 flex-grow">
              <div
                v-for="(component, index) in dashboardComponents"
                :key="index"
                class="dashboard-component rounded-lg p-3 mb-2 cursor-pointer transform transition-transform duration-300 ease-in-out hover:scale-105"
                :class="{ 'border effect': component.component === selectedComponent }"
                @click="handleComponentClick(component)"
              >
                <h2 class="text-lg font-semibold">
                  {{ component.title }}
                </h2>
              </div>
            </section>
          </aside>
        </div>

        <div class="flex-1 relative overflow-auto">
          <component 
            :is="selectedComponent" 
            v-if="!loading" 
          />
          <LoadingTemplate 
            v-if="loading" 
            class="absolute-center" 
            @loading-complete="loadingCompleteHandler" 
          />
        </div>
      </div>
    </div>
    <Toast />
  </div>
</template>

<script setup>
  import { ref, reactive, onMounted, watch } from 'vue';
  import DispararCampanhaComponent from '../../components/whats/DispararCampanhaComponent.vue';
  import { connectionWp } from '../../socket';
  import socket from '../../socket';
  import { markRaw } from 'vue';
  import Toast from 'primevue/toast';

  const showAside = ref(true);
  const qrCodeUrl = ref(''); // URL para o QR Code
  const error = ref(false); // Estado de erro
  const errorMessage = ref(''); // Mensagem de erro
  const isLogged = ref(false); // Verifica se o usuário está logado
  const isLoading = ref(true); // Controle de estado de carregamento
  const messageQr = ref('Scaneie com o QR code do WhatsApp para conectar ao Bot de Marketing!'); // Mensagem do QR Code
  const selectedComponent = ref(null);
  const loading = ref(false); // Estado de loading para componente selecionado

  const dashboardComponents = reactive([
    { title: 'Disparar Campanha', component: markRaw(DispararCampanhaComponent) },
    // Outros componentes podem ser adicionados aqui
  ]);

  // Função chamada quando um componente do dashboard é clicado
  const handleComponentClick = (component) => {
    selectComponent(component.component);
    if (showAside.value) {
      toggleAside();
    }
  };

  // Seleciona o componente e ativa o loading
  const selectComponent = (component) => {
    loading.value = true;
    selectedComponent.value = component;
  };

  // Função de fade-in da imagem
  const fadeIn = (event) => {
    event.target.style.opacity = 1;
  };

  // Atualiza a URL do QR Code gerada a partir do ArrayBuffer
  const updateQRCodeUrl = (imageData) => {
    const blob = new Blob([new Uint8Array(imageData)], { type: 'image/png' });
    qrCodeUrl.value = URL.createObjectURL(blob);
  };

  // Alterna a exibição da barra lateral
  const toggleAside = () => {
    showAside.value = !showAside.value;
  };

  // Busca o QR Code
  const fetchQRCode = () => {
    error.value = false;
    isLoading.value = true;

    const updateIsLogged = (value) => {
      isLogged.value = value;
      sessionStorage.setItem('isLogged', value);
    };

    const qrCodeMessage = (value) => {
      messageQr.value = value;
    };

    connectionWp('botMarketing', updateIsLogged, qrCodeMessage)
      .then(qrCodeData => {
        if (qrCodeData && qrCodeData.qrCodePath && qrCodeData.imageData instanceof ArrayBuffer) {
          updateQRCodeUrl(qrCodeData.imageData);
          isLoading.value = false;
        } else {
          error.value = true;
          errorMessage.value = 'Erro ao carregar o QR Code. Por favor, tente novamente!';
        }
      })
      .catch(error => {
        error.value = true;
        errorMessage.value = 'Erro ao obter os dados do QR Code: ' + error.message;
      });
  };

  // Manipula o estado de loading completo
  const loadingCompleteHandler = () => {
    loading.value = false;
  };

  onMounted(() => {
    const loggedStatus = sessionStorage.getItem('isLogged') === 'true';
    if (!loggedStatus) {
      fetchQRCode();
    } else {
      isLogged.value = loggedStatus;
      isLoading.value = false;
    }

    if (socket) {
      socket.on('qrReadFail', (message) => {
        errorMessage.value = message;
        error.value = true;
      });
    }
  });

  // Recarrega o QR Code quando o estado de login muda
  watch(isLogged, (newVal) => {
    if (!newVal) {
      fetchQRCode();
    }
  });

  // Monitora os estados de login e loading
  watch([isLogged, isLoading], ([newIsLogged, newIsLoading]) => {
    console.log('isLogged:', newIsLogged);
    console.log('isLoading:', newIsLoading);
  });
</script>


<style scoped>

  .light-theme .btn-toggle-aside{
    background-color: #0c6431;
  }

  .light-theme .btn-toggle-aside span{
    color: black;
    
  }

  .light-theme .showAside{
    background-color: #052E16;
  }

  .light-theme .showAside span{
    color: white;
    font-weight: lighter;
  }

  .light-theme .dashboard{
    background-color: #01A13E;
    border-right:2px solid #052E16;
  }

  .light-theme .dashboard-component{
    background-color: #ffffff;
    border: solid 2px #052E16;
  }

  .dark-theme .btn-toggle-aside{
    background-color: #028534;
  }

  .dark-theme .btn-toggle-aside span{
    color: black;
  }

  .dark-theme .showAside{
    background-color: #01A13E;
  }

  .dark-theme .showAside span{
    
    color: white;
    font-weight: darker;
  }

  .dark-theme .dashboard{
    background-color:#015220;
    border-right:2px solid #01A13E;
  }

  .dark-theme .dashboard-component{
    background-color:#01A13E;
    border: solid 2px white;
  }

  .typewriter {
    overflow: hidden;
    white-space: nowrap;
    margin: 0 auto; 
    letter-spacing: .15em;
    animation: typing 3s steps(40, end) infinite, blink-caret .30s step-end infinite;
  }

  @keyframes typing {
    from {
      width: 0;
    }
    to {
      width: 100%;
    }
  }

  .opacity-0 {
    opacity: 0;
  }
  
  .transition-opacity {
    transition: opacity 0.7s;
  }
  
  .absolute-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

</style>


