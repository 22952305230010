<template>
  <div>
    <HeaderTemplate />
    <LoadingTemplate @loading-complete="loadingCompleteHandler" />
    <div class="conteudo" v-if="!loading">
      <div class="container" v-for="(item, index) in serverItems" :key="index">  
        <h2 @click="toggleFolder(index)" class="folder">{{ item.folder }}</h2>
        <ul v-show="isFolderOpen(index)" class="subfolders">
          <li v-for="(subfolder, subIndex) in item.subfolders" :key="subIndex">
            <h3 @click="toggleSubfolder(index, subIndex)" class="subfolder">{{ subfolder.folder }}</h3>
            <ul v-show="isSubfolderOpen(index, subIndex)" class="pages">
              <li v-for="page in subfolder.pages" :key="page.name" class="page">
                <a :href="getPageUrl(item.folder, subfolder.folder, page.name)" target="_blank">{{ page.name }}</a>
              </li>
            </ul>
          </li>
          <template v-if="!item.subfolders || item.subfolders.length === 0">
            <li v-for="page in item.pages" :key="page.name" class="page">
              <a :href="getPageUrl(item.folder, '', page.name)">{{ page.name }}</a>
            </li>
          </template>
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useLoading } from '../../mixin/loadingMixin';

const { loading, loadingCompleteHandler } = useLoading();

const serverItems = ref([]);
const openFolders = ref([]);
const openSubfolders = ref([]);

const fetchData = () => {
  fetch(`${process.env.VUE_APP_BASE_URL}/iframes`)
    .then(response => response.json())
    .then(data => {
      serverItems.value = data;
    })
    .catch(error => {
      console.error("Erro ao obter dados do servidor:", error);
    });
};

const getPageUrl = (folder, subfolder, pageName) => {
  let baseUrl = `${process.env.VUE_APP_BASE_URL}/iframes/`;
  if (folder) {
    baseUrl += folder + "/";
  }
  if (subfolder) {
    baseUrl += subfolder + "/";
  }
  return baseUrl + pageName;
};

const toggleFolder = (index) => {
  if (isFolderOpen(index)) {
    openFolders.value.splice(openFolders.value.indexOf(index), 1);
  } else {
    openFolders.value.push(index);
  }
};

const toggleSubfolder = (parentIndex, subIndex) => {
  const key = `${parentIndex}-${subIndex}`;
  if (isSubfolderOpen(parentIndex, subIndex)) {
    openSubfolders.value.splice(openSubfolders.value.indexOf(key), 1);
  } else {
    openSubfolders.value.push(key);
  }
};

const isFolderOpen = (index) => {
  return openFolders.value.includes(index);
};

const isSubfolderOpen = (parentIndex, subIndex) => {
  const key = `${parentIndex}-${subIndex}`;
  return openSubfolders.value.includes(key);
};

onMounted(() => {
  fetchData();
});
</script>



  
<style scoped>
    .conteudo{
      padding: 50px;
    }
    .container {
      padding: 20px;
      width: 600px;
      background-color: #f9f9f9;
      border-radius: 8px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      margin: auto; 
      margin-bottom: 20px;
    }

    .folder {
      cursor: pointer;
      margin-bottom: 10px;
      color: #c70000;
      font-size: 18px;
      font-weight: bold;
      text-transform: uppercase;
      font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
      display: flex;
      align-items: center;
    }
    .folder::after {
      content: " 📁";
      margin-left: 5px;
    }


    .subfolder {
      cursor: pointer;
      padding: 5px 0;
      color: #4caf50;
      font-size: 16px;
      font-weight: bold;
      text-transform: uppercase;
      font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    }

    .subfolders, .pages {
      padding-left: 20px;
      list-style-type: none;
    }

    .page, li {
      margin-bottom: 10px;
      color: #333;
      font-size: 14px;
    }
</style>
