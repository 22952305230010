<template>
  <div 
    id="templates" 
    class="flex justify-center items-center p-4"
  >
    <div class="w-full max-w-md p-6 ">
      <h2 class="text-xl font-semibold mb-4 text-center">
        Selecione a Promoção
      </h2>
      <ul class="space-y-2">
        <li
          v-for="message in filteredMessages"
          :key="message._id"
          :class="[
            'cursor-pointer p-4 border rounded-lg transition-colors duration-200 bg-white',
            selectedTemplate && selectedTemplate._id === message._id ? 'border-blue-300' : 'hover:bg-gray-200'
          ]"
          @click="selectTemplate(message)"
        >
          {{ message.templateName }} - {{ new Date(message.date).toLocaleDateString() }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
  import { defineProps, defineEmits } from 'vue';

  defineProps({
    filteredMessages: {
      type: Array,
      required: true
    },
    // eslint-disable-next-line vue/require-default-prop
    selectedTemplate: {
      type: Object,
      required: false
    }
  });

  const emit = defineEmits(['select-template']);

  const selectTemplate = (template) => {
    emit('select-template', template);
  };
</script>