<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="flex flex-col gap-4">
    <div 
      v-if="campaign"
      class="campaign-container flex flex-col items-center justify-center max-w-5xl mx-auto p-4 border-2 rounded-lg border-green-600"
    >
      <div 
        v-if="campaign.imagem" 
        class="campaign-media flex flex-col items-center mb-4"
      >
        <img 
          :src="campaign.imagem" 
          alt="imagem da campanha" 
          class="campaign-image rounded border"
        >
      </div>

      <div 
        class="text-xl mb-4 text-center"
        v-html="campaign.titulo" 
      />

      <div 
        class="mb-4 text-center"
        v-html="campaign.conteudo"
      />


      <div 
        v-if="campaign.legenda"
        class="mb-2 text-center"
      >
        <a 
          :href="campaign.legenda" 
          target="_blank" 
          class="text-blue-500 hover:underline"
        >
          {{ campaign.legenda }}
        </a>
      </div>
    </div>
    <div v-else>
      <p>No campaign selected.</p>
    </div>
    <div class="campaign-actions justify-center flex gap-3">
      <PrimeButton 
        severity="success"
        label="Confirmar campanha"
        @click="selectList"
      />
      <PrimeButton 
        severity="danger"
        label="Excluir campanha"
        @click="confirmDeletion"
      />

      <ConfirmDialog />
      <PrimeButton
        severity="warn"
        label="voltar" 
        @click="voltar" 
      />
    </div>
  </div>
</template>

<script setup>
  import { defineProps, defineEmits } from 'vue';
  import { useToast } from 'primevue/usetoast';
  import { useConfirm } from 'primevue/useconfirm';
  import ConfirmDialog from 'primevue/confirmdialog';

  const props = defineProps({
    campaign: {
      type: Object,
      required: false
    }
  });
  const confirm = useConfirm();
  const toast = useToast();
  const emit = defineEmits(['selectList', 'campaignDeleted', 'voltarSelecionarCampanha']);
  
  const selectList = () => {
    emit('selectList', props.campaign);
    console.log("campanha selecionado", props.campaign)
  };

  const deleteCampaign = async () => {
    try {
      const response = await fetch(`${process.env.VUE_APP_BASE_URL}/whats/campaignsInGeneral/${props.campaign._id}`, {
        method: 'DELETE'
      });
      const result = await response.json();
      if (response.ok) {
        toast.add({ 
          severity: 'success', 
          summary: 'Sucesso', 
          detail: 'Campanha deletada com sucesso', 
          life: 3000 
        });
        emit('campaignDeleted');
      } else {
        console.error('Erro ao deletar campanha:', result.message);
        toast.add({ 
          severity: 'error', 
          summary: 'Erro', 
          detail: `Erro ao deletar campanha: ${result.message}`, 
          life: 3000 
        });
      }
    } catch (error) {
      console.error('Erro ao deletar campanha:', error);
      toast.add({ 
        severity: 'error', 
        summary: 'Erro', 
        detail: 'Erro ao deletar campanha', 
        life: 3000 
      });
    }
  };

  const confirmDeletion = () => {
    confirm.require({
      message: 'Tem certeza que deseja excluir esta campanha?',
      header: 'Confirmação',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Excluir',
      rejectLabel: 'Cancelar',
      acceptClass: 'p-button-danger i',
      rejectClass: 'p-button-secondary',
      accept: () => {
        deleteCampaign();
      },
    });
  };

  const voltar = () => {
    emit('voltarSelecionarCampanha');
  };
</script>

<style scoped>
  .campaign-image {
    width: 300px;
    height: 300px;
  }
</style>
