import store from '../store/index.js';
import Cookies from 'js-cookie';

export async function initializeAuth() {
  const token = Cookies.get('token');
  if (token) {
    store.commit('setUserToken', token);
    try {
      const response = await fetch(`${process.env.VUE_APP_BASE_URL}/user`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });

      const data = await response.json();

      store.commit('setUserData', data);
    } catch (error) {
      console.error('Erro ao recuperar dados do usuário:', error);
    }
  }
}

export function checkAuthentication(to, from, next) {
  const token = Cookies.get('token');
  if (token) {
    next();
  } else {
    next('');
  }
}

export function includeAuthHeaders() {
  const token = Cookies.get('token');
  return {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  };
}
