<template>
  <div class="flex flex-col items-center justify-center min-h-full gap-2">
    <h2 class="text-xl font-bold">
      Selecione ou Crie uma nova Lista
    </h2>

    <div class="flex gap-2">
      <PrimeButton
        severity="success"
        label="Criar Lista" 
        @click="createNewList"
      />
      
      <PrimeButton 
        label="Selecionar Lista"
        severity="info"
        @click="selectList"
      />

      <PrimeButton 
        severity="warn"
        label="Selecionar ou Criar outra Campanha"
        @click="voltar"
      />
    </div>
  </div>
</template>
    
<script setup>
  import { defineEmits } from 'vue';

  const emit = defineEmits(['create-list', 'select-list', 'voltarcampaign']);

  const createNewList = () => {
    emit('create-list');
  };

  const selectList = () => {
    emit('select-list');
  };

  const voltar = () => {
    emit('voltarcampaign');
  };
</script>