import { createApp } from 'vue';
import App from './App.vue';
import store from '../store/index.js';
import router from './routes/routes.js'; 

import PrimeVue from 'primevue/config';
import Aura from '@primevue/themes/aura';
import Ripple from 'primevue/ripple';
import ToastService from 'primevue/toastservice';
import PrimeButton from 'primevue/button';
import ConfirmationService from 'primevue/confirmationservice';
import Tooltip from 'primevue/tooltip';

/* import '@fortawesome/fontawesome-free/css/all.min.css';  */

import 'primeicons/primeicons.css';
import './assets/index.css';
import './assets/theme.css';
import { includeAuthHeaders, initializeAuth } from '../store/auth';

import HeaderTemplate from './components/header/HeaderTemplate.vue';
import LoadingTemplate from './components/header/LoadingTemplate.vue';


const app = createApp(App);

app.use(PrimeVue, {
  theme:{
    preset: Aura,
    options: {
      prefix: 'p',
      darkModeSelector: '.my-app-dark',
      cssLayer:false
    }
  },
  locale:{
    accept:'Aceptar',
    reject:'Rechazar'
  }
});

app.use(ConfirmationService);
app.use(ToastService);
app.component('HeaderTemplate', HeaderTemplate);
app.component('LoadingTemplate', LoadingTemplate);
app.component('PrimeButton', PrimeButton);
app.directive('ripple', Ripple);
app.directive('tooltip', Tooltip);

app.config.globalProperties.$includeAuthHeaders = includeAuthHeaders;

initializeAuth().then(() => {
  app.use(router).use(store).mount('#app');
});
