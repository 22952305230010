<template>
  <div>
    <div class="text-center w-full">
      <div class="w-full max-w-3xl">
        <h2 class="font-bold text-white text-center text-lg break-words p-4">
          3° Verifique se este é o template escolhido.
        </h2>
      </div>
    </div>

    <div class="w-full flex justify-center gap-2">
      <PrimeButton 
        v-tooltip.left="'Clique e visualize o template'"
        type="button"
        severity="info"
        label="Verificar template"
        @click="visible = true"
      />
      
      <PrimeButton
        type="button"
        severity="success"
        label="Confirmar Template"
        @click="showTemplate($event)"
      />

      <PrimeButton
        type="button"
        severity="warn"
        label="Outro Template"
        @click="voltarSelecaoTemplate()"
      />


      <PrimeButton 
        v-tooltip="'Ajuda'"
        type="button"
        class="botaoDeAjuda"
        label="Ajuda"
        icon="pi pi-question-circle"
        icon-pos="right" 
        @click="visibleRight = true"      
      />
    </div>

    <ConfirmPopup group="templating">
      <template #message="slotProps">
        <div class="flex flex-col items-center w-full gap-4 border-b border-surface-200 dark:border-surface-700 p-4 mb-4 pb-0">
          <i :class="slotProps.message.icon" class="text-6xl text-primary-500" />
          <p>{{ slotProps.message.message }}</p>
        </div>
      </template>
    </ConfirmPopup>

    <div class="w-full flex justify-center">
      <Dialog
        v-if="props.selectedTemplate"
        header="Pre-visualização"
        maximizable
        v-model:visible="visible"
        :modal="true"
        :closable="true"
        :draggable="true"
        :resizable="false"
        :style="{ width: '50rem' }"
        :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
      >
        <div class="iframe-container">
          <iframe
            :src="templateLink"
            frameborder="0"
            class="iframe-content"
          />
        </div>
      </Dialog>
    </div>

    <div class="p-4 w-full max-w-3xl mx-auto mt-4">
      <div class="text-center w-full break-words">
        <p class="text-white font-bold">
          Link do Template para conferência<br>
          <a
            :href="templateLink"
            target="_blank"
            class="text-red-600 py-1 rounded-lg px-1 hover:underline break-all"
          >
            {{ templateLink }}
          </a>
        </p>
      </div>
    </div>

    <Drawer 
      v-model:visible="visibleRight"
      header="Precisando de ajuda?" 
      position="right"
    >
      <p> informações </p>
    </Drawer>
  </div>
</template>

<script setup>
  import { ref, computed, defineProps, defineEmits } from 'vue';
  import { useToast } from 'primevue/usetoast';
  import { useConfirm } from "primevue/useconfirm";

  import ConfirmPopup from 'primevue/confirmpopup';
  import Dialog from 'primevue/dialog';
  import Drawer from 'primevue/drawer';

  const toast = useToast();
  const confirm = useConfirm();
  const emit = defineEmits(['VoltarSelecionarTemplate','confirmarTemplate']);

  const props = defineProps({
    selectedTemplate: {
      type: Object,
      required: true
    }
  });

  const visible = ref(false);
  const visibleRight = ref(false);

  const showTemplate = (event) => {
      confirm.require({
          target: event.currentTarget,
          group: 'templating',
          message: 'Confirmar Template?',
          icon: 'pi pi-exclamation-circle',
          rejectProps: {
              icon: 'pi pi-times',
              label: 'Cancelar',
              outlined: true
          },
          acceptProps: {
              icon: 'pi pi-check',
              label: 'confirmar'
          },
          accept: () => {
              emit('confirmarTemplate');
              toast.add({severity:'success', summary:'Template selecionado', life: 2000});
          },

      });
  }


  const voltarSelecaoTemplate = () =>{
    emit('VoltarSelecionarTemplate')

  }

  const templateLink = computed(() => {
    if (props.selectedTemplate && props.selectedTemplate.filename) {
      return `${process.env.VUE_APP_BASE_URL}/email/templateEmails/${props.selectedTemplate.filename}`;
    }
    return '';
  });

</script>
 
<style scoped>
  .iframe-container {
    width: 100%;
    position: relative;

    padding-bottom: 56.25%;
    height: 100%;
  }

  .iframe-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
  }

  .light-theme .botaoDeAjuda,
  .dark-theme .botaoDeAjuda {
    background-color: rgb(192, 192, 192);
    border-color:rgb(192, 192, 192);
  }

  .light-theme .botaoDeAjuda:hover,
  .dark-theme .botaoDeAjuda:hover{
    background-color: rgba(114, 114, 114, 0.685);
    border-color:rgba(114, 114, 114, 0.685);
  }
</style>
