import { ref } from 'vue';

export const currentTheme = ref('light-theme');

export const toggleTheme = () => {
  const newTheme = currentTheme.value === 'light-theme' ? 'dark-theme' : 'light-theme';
  document.documentElement.classList.remove('light-theme', 'dark-theme');
  document.documentElement.classList.add(newTheme);
  currentTheme.value = newTheme;
};

document.documentElement.classList.add(currentTheme.value);
