<template>
    <div class="flex flex-col min-h-screen w-screen">
        <HeaderTemplate />
        <LoadingTemplate 
            @loading-complete="loadingCompleteHandler" 
        />
        <div class="flex flex-1 items-center justify-center" v-if="!loading">
            <div class="conteudo flex w-full max-w-3xl justify-center">
              <viewUsuariosCadastrados :key="usuariosKey" />
              <CadastroUsuario @usuario-cadastrado="atualizarUsuarios" />
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue';
import viewUsuariosCadastrados from '../../components/usuarios/UsuariosCadastrados';
import CadastroUsuario from '../../components/usuarios/CadastroUsuarioTemplate';
import { useLoading } from '../../mixin/loadingMixin';

const { loading, loadingCompleteHandler } = useLoading();
// Reatividade

const usuariosKey = ref(0);

// Métodos
const atualizarUsuarios = () => {
  usuariosKey.value += 1;
};


</script>

<style scoped>
    .light-theme .conteudo {
        border: 2px solid #038D38;
        transition: all 0.3s ease;
        animation: fadeIn 0.5s ease forwards;
        border-radius: 10px;
    }

    .dark-theme .conteudo {
        border: 2px solid #038D38;
        transition: all 0.3s ease;
        animation: fadeIn 0.5s ease forwards;
        border-radius: 10px;
    }
</style>