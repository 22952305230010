<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="flex flex-col gap-1">
    <div 
      v-if="loading"
    >
      Loading...
    </div>
    <div 
      v-if="error"
    >
      {{ error }}
    </div>

    <div 
      v-if="promocoesGerais.length" 
      class="w-full selectPromocoes"
    >
      <Select 
        v-model="selectedPromocao" 
        :options="promocoesGerais" 
        filter 
        placeholder="Selecione uma campanha" 
        option-label="titulo" 
        class="w-full"
        @change="emitSelectedPromocao"
      >
        <template #value="slotProps">
          <div 
            v-if="slotProps.value" 
            class="flex items-center bg-red-200 p-2"
          >
            <div 
              v-html="slotProps.value.titulo" 
            />
          </div>
          <span 
            v-else
          >
            {{ slotProps.placeholder }}
          </span>
        </template>
        <template 
          #option="slotProps"
        >
          <div 
            class="flex items-center"
          >
            <div 
              v-html="slotProps.option.titulo"
            />
          </div>
        </template>
      </Select>
    </div>

    <div 
      v-if="!loading && !promocoesGerais.length"
    >
      Sem promoções.
    </div>

    <PrimeButton 
      severity="warn"
      label="voltar"
      @click="voltar"
    />
  </div>
</template>

<script setup>
  import { ref, onMounted, defineEmits } from 'vue';
  import Select from 'primevue/select';

  const promocoesGerais = ref([]);
  const selectedPromocao = ref('');
  const loading = ref(false);
  const error = ref(null);

  const emit = defineEmits(['promocao-selecionada', 'voltarSelecao']);

  const fetchPromocoes = async () => {
    loading.value = true;
    try {
      const response = await fetch(`${process.env.VUE_APP_BASE_URL}/whats/campaignsInGeneral`);
      if (!response.ok) {
        throw new Error('Failed to fetch promotions');
      }
      const data = await response.json();
      promocoesGerais.value = data.todasCampanhas || [];
    } catch (err) {
      error.value = err.message;
    } finally {
      loading.value = false;
    }
  };

  const emitSelectedPromocao = () => {
    emit('promocao-selecionada', selectedPromocao.value);
  };

  const voltar = () => {
    emit('voltarSelecao');
  };

  onMounted(fetchPromocoes);
</script>

<style scoped>
  .light-theme .selectPromocoes{
    width: 300px;
  }
</style>