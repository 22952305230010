<template>
  <div class="flex flex-col items-center justify-center min-h-full gap-2">
    <h2 class="text-xl font-bold">
      1° Selecione ou Crie uma nova Campanha
    </h2>
    <div class="flex gap-2">
      <PrimeButton 
        label="Criar nova campanha" 
        severity="success"
        @click="createNewCampaign"
      />
      <PrimeButton 
        label="Selecionar Campanha"
        severity="info"
        @click="selectCampaign" 
      />
    </div>
  </div>
</template>
  
<script setup>
  import { defineEmits } from 'vue';

  const emit = defineEmits(['create-campaign', 'select-campaign']);

  const createNewCampaign = () => {
    emit('create-campaign');
  };

  const selectCampaign = () => {
    emit('select-campaign');
  };
</script>