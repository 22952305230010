<template>
  <div class="loading-container">
    <ProgressSpinner 
      v-if="isLoading"
      style="width: 50px; height: 50px" 
      fill="transparent"
      stroke-width="8" 
      animation-duration=".5s" 
      aria-label="Custom ProgressSpinner" 
    />
  </div>
</template>

<script setup>
  import { ref, onMounted, defineEmits } from 'vue';
  import ProgressSpinner from 'primevue/progressspinner';

  const isLoading = ref(true);
  const emit = defineEmits(['loading-complete']);

  onMounted(() => {
    setTimeout(() => {
      isLoading.value = false;
      emit('loading-complete');
    }, 2000);
  });
</script>

<style>
  .loading-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
    gap: 10px;
  }

  .dark-theme .p-progressspinner-circle {
    --p-progressspinner-color-1: #015220;
    --p-progressspinner-color-2: red;
    --p-progressspinner-color-3: #015220;
    --p-progressspinner-color-4: red;
  }

  .light-theme .p-progressspinner-circle {
    --p-progressspinner-color-1: #01A13E;
    --p-progressspinner-color-2: red;
    --p-progressspinner-color-3: #01A13E;
    --p-progressspinner-color-4: red;
  }
</style>
