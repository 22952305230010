import io from 'socket.io-client';

const socket = io(`${process.env.VUE_APP_BASE_URL_SOCKET}`, {
  path: '/socket.io',
  reconnection: true,
  reconnectionDelay: 1000,
});

socket.on('connect', () => {
  console.log('Socket connected');
});

socket.on('disconnect', () => {
  console.log('Socket disconnected');
});

socket.on('connect_error', (error) => {
  console.error('Erro de conexão:', error);
});

export function onEmailTracked(callback) {
  socket.on('emailTracked', callback);
}

export function connectionWp(session, callback, message) {
  return new Promise((resolve, reject) => {
    socket.emit('requestConnection', session);
    
    socket.on('qrCodePath', (data) => {
      console.log("imagem do qrcode em buffer", data.imageData)
      resolve(data);
    });

    socket.on('qrReadFail', (value) => {
      message(value);
    });

    socket.on('alreadyLoggedIn', (value) => {
      callback(value);
    });

    socket.on('requestConnectionError', (error) => {
      reject(error);
    });
  });
}

export default socket;
