<template>
  <div class="w-full flex flex-col flex-grow">
    <div v-if="isVisible">
      <div 
        v-if="!selectedTemplate && !isCreatingTemplate && !isDeletingTemplate" 
        class="w-full flex flex-col gap-4 p-4 items-center"
      >
        <div class="w-full max-w-3xl">
          <h2 class="font-bold text-center text-lg break-words">
            2° Escolha ou Crie um Template (Email que será disparado)
          </h2>
        </div>
        <Select
          v-model="selectedTemplate"
          placeholder="Selecione um Template"
          filter
          :options="templates"
          class="w-full max-w-md text-center font-bold text-red-700 text-lg border-2 rounded-md focus:ring-2 cursor-pointer"
          option-label="name"
        />
        <div class="flex flex-col sm:flex-row gap-2 justify-center w-full max-w-3xl">
          <PrimeButton 
            label="Criar Template" 
            icon="pi pi-plus" 
            class="p-button-success" 
            @click="createTemplate" 
          />
          <PrimeButton 
            label="Excluir Template" 
            icon="pi pi-trash" 
            class="p-button-danger" 
            @click="deleteTemplate" 
          />
          <PrimeButton
            label="Selecionar Remetente" 
            icon="pi pi-user" 
            severity="warn"
            @click="cancel" 
          />
        </div>
      </div>
      <div 
        v-if="isCreatingTemplate" 
        class="flex justify-center"
      >
        <CriarTemplate 
          @templateCriado="handleTemplateCriado"
          @cancelandoCriacao="handleCreateTemplateCancel"
        />
      </div>
      <div v-if="isDeletingTemplate">
        <ExcluirTemplate 
          @templateExcluido="handleTemplateExcluido"
          @cancel="handleDeleteTemplateCancel"
        />
      </div>
      <div 
        v-if="selectedTemplate && !isCreatingTemplate && !isDeletingTemplate" 
        class="w-full flex flex-col justify-center items-center gap-3"
      >
        <ViewTemplate 
          :selected-template="selectedTemplate" 
          @VoltarSelecionarTemplate="voltarSelecionarTemplate"
          @confirmarTemplate="confirmTemplate"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
  import { ref, onMounted, defineEmits, watch } from 'vue';
  import Select from 'primevue/select';
  import { useToast } from 'primevue/usetoast';
  import CriarTemplate from './CriarTemplate';
  import ExcluirTemplate from './ExcluirTemplate';
  import ViewTemplate from './viewTemplate';
  
  const toast = useToast();
  const templates = ref([]);
  const selectedTemplate = ref(null); 
  
  const isVisible = ref(true);
  const isCreatingTemplate = ref(false);
  const isDeletingTemplate = ref(false);
  
  const emit = defineEmits(['remetenteSelecionado','cancelandoSelecionarTemplate','templateSelecionado']);
  
  async function fetchTemplates() {
    try {
      const response = await fetch(`${process.env.VUE_APP_BASE_URL}/email/templateEmails`);
      const data = await response.json();
      templates.value = data.templates;
    } catch (error) {
      console.error('Erro ao obter os dados dos templates:', error);
    }
  }
  
  function cancel() {
    emit("cancelandoSelecionarTemplate");
  }
  
  function createTemplate() {
    toast.add({ severity: 'info', summary: 'Criando Template Marketing', life: 2000 });
    isCreatingTemplate.value = true;
  }
  
  function deleteTemplate() {
    toast.add({ severity: 'warn', summary: 'Aviso', detail: 'Cuidado para não deletar o template errado', life: 2000 });
    isDeletingTemplate.value = true;
  }
  
  function handleTemplateCriado() {
    isCreatingTemplate.value = false;
    fetchTemplates();
  }
  
  function handleCreateTemplateCancel() {
    isCreatingTemplate.value = false;
  }
  
  function handleTemplateExcluido() {
    isDeletingTemplate.value = false;
    fetchTemplates();
  }
  
  function handleDeleteTemplateCancel() {
    isDeletingTemplate.value = false;
  }
  
  function voltarSelecionarTemplate() {
    selectedTemplate.value = null;
    toast.add({ severity: 'info', summary: 'Selecione, crie ou exclua um template', life: 2000 });
  }
  
  function confirmTemplate() {
    emit('templateSelecionado', selectedTemplate.value);
  }
  
  watch(selectedTemplate, (newVal) => {
    if (newVal) {
      toast.add({ severity: 'success', summary: 'Template Selecionado', detail: `Template ${newVal.name} foi selecionado`, life: 2000 });
    }
  });

  onMounted(() => {
    fetchTemplates();
  });
</script>
