import { createStore } from 'vuex';

const USER_DATA_KEY = 'user_data';

export default createStore({
  state: {
    userData: JSON.parse(localStorage.getItem(USER_DATA_KEY)) || {},
    userToken: null
  },
  mutations: {
    setUserData(state, userData) {
      state.userData = userData;
    },
    setUserToken(state, userToken) {
      state.userToken = userToken;
    },
    clearUserData(state) {
      state.userData = {};
      state.userToken = null;
      localStorage.removeItem(USER_DATA_KEY);
    }
  },
  actions: {},
  modules: {}
});
