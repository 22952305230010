import { createRouter, createWebHistory } from 'vue-router';
import LoginPage from '../view/LoginPage.vue';
import HomePage from '../view/HomePage.vue';
import UsuarioPage from '../view/usuarios/UsuarioPage.vue';
import EmailPage from '../view/emailMarketing/EmailPage.vue';
import EmailCrediarioPage from '../view/emailCrediario/EmailCrediario.vue';
import AnalyticsMarketing from '../view/emailMarketing/AnalyticsMarketing.vue';
import AnalyticsCrediario from '../view/emailCrediario/AnalyticsCrediario.vue';
import IframesPages from '../view/iframes/IframesPage.vue';
import WhatsMarketingPage from '../view/whatsApp/WhatsMarketingPage.vue'
import { checkAuthentication } from '../../store/auth';

const routes = [
  { path: '', name:'login' ,component: LoginPage },
  { path: '', name: 'home', component: HomePage, beforeEnter: checkAuthentication },
  { path: '/email', name: 'emailMarketing', component: EmailPage, beforeEnter: checkAuthentication},
  { path: '/analytics', name: 'analyticsMarketing', component: AnalyticsMarketing, beforeEnter: checkAuthentication},
  { path: '/emailCrediario', name: 'EmailCrediarioPage', component: EmailCrediarioPage,  beforeEnter: checkAuthentication},
  { path: '/analyticsCrediario', name: 'AnalyticsCrediario', component: AnalyticsCrediario, beforeEnter: checkAuthentication},
  { path: '', name: 'iframes', component: IframesPages,  beforeEnter: checkAuthentication},
  { path: '', name: 'UsuarioPagina', component: UsuarioPage, beforeEnter: checkAuthentication},
  { path: '/whats', name: 'WhatsMarketingPage', component: WhatsMarketingPage, beforeEnter: checkAuthentication}
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;

