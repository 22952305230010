import { ref } from 'vue';

export function useLoading() {
  const loading = ref(true);

  function loadingCompleteHandler() {
    loading.value = false;
  }

  return {
    loading,
    loadingCompleteHandler
  };
}
