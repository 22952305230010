<template>
  <div class="flex flex-col min-h-screen ">
    <div>
      <HeaderTemplate />
      <LoadingTemplate 
        @loading-complete="loadingCompleteHandler" 
      />
      <Toast 
        position="bottom-right" 
      />
    </div>
  
    <div 
      class="flex flex-col flex-grow"
    >
      <div 
        v-if="!loading" 
        class="flex flex-col m-2 items-center justify-center flex-grow gap-1"
      > 
        <div 
          class="w-full max-w-3xl"
        > 
          <RemetenteEmail 
            v-if="shouldShowRemetenteEmail" 
            @remetenteSelecionado="SelectedRemetente" 
          />
        </div>

        <div 
          class="w-full max-w-3xl"
        >
          <SelecionarTemplate 
            v-if="showTemplateSelector" 
            ref="selecionarTemplate" 
            @templateSelecionado="selectedTemplate" 
            @resetRemetente="voltarRemetente" 
            @cancelandoSelecionarTemplate="voltarRemetente" 
          />
        </div>

        <div 
          v-if="shouldShowCriarOuBuscarLista"
          class="w-full max-w-3xl flex flex-col gap-2 text-center justify-center p-4"
        >
          <div class="w-full max-w-3xl">
            <BuscarOuCriaLista 
              @createList="createList" 
              @fetchList="toggleFileInput"
              @voltarTemplate="voltarTemplate"
            />
          </div>
        </div>

        <div 
          v-if="list" 
          class="w-full flex flex-col items-center justify-center"
        >
          <div 
            class="w-full max-w-3xl flex flex-col gap-4 text-center justify-center p-2 overflow-hidden"
          >
            <div 
              v-if="shouldShowAdicionarOuCarregarDestinatarios" 
              class="flex justify-center max-w-full"
            >
              <p class="font-bold passoCinco text-lg p-1 text-center w-full break-words">
                5° Escolha <strong class="text-red-700">Adicionar Destinatário</strong> se desejar adicionar manualmente ou 
                <strong class="text-red-700">Carregar Destinatários</strong> para importar um arquivo de texto contendo os destinatários. 
                <strong class="text-red-700">O formato padrão para carregar os arquivos .txt é: Nome|email.</strong>
              </p>
            </div>

            <div class="flex overflow-hidden gap-4 text-center justify-center">
              <AdicionarEmail 
                v-if="mostrarEmails" 
                @toggleAddManually="handleCancelAddManually" 
                @listNameChanged="handleListNameChanged" 
                @updateRecipientList="handleUpdatedRecipientList"
                @DispararEmailManual="verificarListaName"
              />
              <EmailsInput 
                v-if="mostrarEmailsInput" 
                ref="emailsInput" 
                @file-data-updated="handleFileDataUpdated" 
                @listNameChanged="handleListNameChanged" 
                @voltarCriarOuBuscar="voltarCriarOuBusca"
                @voltarAddOuInputs="voltarAddOuInput"
                @DispararEmailManual="verificarListaName"
              />
            </div>
          </div>
        </div>
        <div 
          v-if="listBanco" 
          class="w-full max-w-md"
        >
          <ListaBanco 
            :dados="dadosBanco" 
            @voltandoParaBuscarOuCriar="handleVoltarListaOuBuscar" 
            @dadosBanco="enviarEmails" 
          />
        </div>
      </div>
    
      <ConfirmDialog />
    </div>
  </div>
</template>

<script setup>
  import { ref, computed } from 'vue';
  import { useStore } from 'vuex';
  import { useLoading } from '../../mixin/loadingMixin';
  import { useToast } from 'primevue/usetoast';
  import { useConfirm } from 'primevue/useconfirm';

  import ConfirmDialog from 'primevue/confirmdialog';
  import RemetenteEmail from '../../components/emails/remetente/RemetenteEmail.vue';
  import SelecionarTemplate from '../../components/emails/template/SelecionarTemplate';
  import BuscarOuCriaLista from '../../components/emails/listasEmail/BuscarOuCriaLista';
  import AdicionarEmail from '../../components/emails/AdicionarEmail/AdicionarEmail';
  import EmailsInput from '../../components/emails/AdicionarEmail/adicionarEmailInput';
  import ListaBanco from '../../components/emails/listasEmail/ListaBanco';
  import Toast from 'primevue/toast'; 

  const confirm = useConfirm();
  const toast = useToast()
  const store = useStore();
  const userData = computed(() => store.state.userData);
  const { loading, loadingCompleteHandler } = useLoading();
  const remetenteSelecionado = ref(null);
  const templateSelecionado = ref(null);
  const showTemplateSelector = ref(false);
  const list = ref(false);
  const mostrarEmailsInput = ref(true);
  const mostrarEmails = ref(true);
  const listBanco = ref(false);
  const dadosBanco = ref([]);
  const recipientList = ref ('')
  const fileData = ref('');
  const listName = ref('');
  
  const isSending = ref(false);

  const SelectedRemetente = function(remetente) {
    toast.add({
    severity: 'success',
    summary: 'Remetente Selecionado',
    detail: `Você selecionou ${remetente}`,
    life: 2000,
  });
    remetenteSelecionado.value = remetente;
    showTemplateSelector.value = true;
  };

  const selectedTemplate = function(template){
    templateSelecionado.value = template;
    showTemplateSelector.value = false
  }

  const shouldShowRemetenteEmail = computed(() => {
    return !showTemplateSelector.value && !templateSelecionado.value && !recipientList.value.length && !fileData.value;
  });

  const shouldShowCriarOuBuscarLista = computed(() => {
    return templateSelecionado.value && !list.value && !listBanco.value;
  });

  const shouldShowAdicionarOuCarregarDestinatarios = computed(() =>{
    return mostrarEmails.value && mostrarEmailsInput.value;
  });

  const handleVoltarListaOuBuscar = function(){
    listBanco.value = false;
  }

  const voltarCriarOuBusca = function() {
    list.value = false;
  };

  const voltarAddOuInput = function(){
    mostrarEmails.value = true;
    mostrarEmailsInput.value = true;
  }

  const handleCancelAddManually = function() {
    mostrarEmailsInput.value = !mostrarEmailsInput.value;
  }

  const handleUpdatedRecipientList = (updatedList) => {
    recipientList.value = updatedList;
  };

  const handleFileDataUpdated = function(updatedFileData){
    fileData.value = updatedFileData;
    mostrarEmails.value = false;
  }

  const handleListNameChanged = function(list) {
    listName.value = list;
  }

  const voltarRemetente = function() {
    toast.add({
      severity: 'info',
      summary: 'voltando para selecionar remetente',
      life: 2000,
    });
    remetenteSelecionado.value = null;
    showTemplateSelector.value = false;
  }

  const voltarTemplate = function() {
    toast.add({
      severity: 'info',
      summary: 'voltando para selecionar Template',
      life: 2000,
    });
    templateSelecionado.value = '';
    showTemplateSelector.value = true;
  }

  const createList = function (){
    list.value = true;
    listBanco.value = false; 
  }

  const toggleFileInput = async function(){
    listBanco.value = true;
    list.value = false;
    try {
      const response = await fetch(`${process.env.VUE_APP_BASE_URL}/email/lista-banco`);
      if (!response.ok) {
        throw new Error('Erro ao buscar dados do banco');
      }
      const data = await response.json();
      dadosBanco.value = data;
    } catch (error) {
      console.error('Erro ao buscar dados do banco:', error);
    }
  }

  const verificarListaName = async () => {
    let mensagem = '';
  
    if (listName.value === '') {
      mensagem = `[IMPORTANTE] Não irá salvar
       a lista!`;
    } else {
      mensagem = `Nome da Lista a ser salva: ${listName.value}`;
    }

    confirm.require({
      header: 'Deseja Enviar e-mail?',
      message: `${mensagem}`,
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Sim, enviar!',
      rejectLabel: 'Não, cancelar',
      acceptIcon: 'pi pi-check',
      rejectIcon: 'pi pi-times',
      accept: async () => {
        sendEmails();
      },
    });
  };

  const sendEmails = async () => {
    isSending.value = true;

    try {
      let destinatarios = "";

      if(recipientList.value != 0){
        destinatarios = recipientList.value.map(recipient => ({
          nome: recipient.name,
          email: recipient.email,
        }));
      }else{
        destinatarios = fileData.value.map(recipient => ({
          nome: recipient.name,
          email: recipient.email,
        }));
      }
     
      const response = await fetch(`${process.env.VUE_APP_BASE_URL}/email/enviar-email`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          lista: listName.value,
          remetente: remetenteSelecionado.value,
          destinatarios: destinatarios,
          template: templateSelecionado.value.name,
          user: userData.value.name,
          departamento:userData.value.permission,
        }),
      });

      if (response.ok) {
        apagarTudo();
      } else {
        throw new Error('Failed to send emails.');
      }
    } catch (error) {
      console.error('Error sending emails:', error);
    } finally {
      isSending.value = false;
    }
  };

  const enviarEmails = async (dadosBanco) => {
    isSending.value = true;
    try {
      const destinatarios = dadosBanco.entries.map((entry) => ({
        nome: entry.name,
        email: entry.email,
      }));

      const response = await fetch(`${process.env.VUE_APP_BASE_URL}/email/enviar-email`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          lista: dadosBanco.list,
          remetente: remetenteSelecionado.value,
          destinatarios: destinatarios,
          template: templateSelecionado.value,
          user: store.state.userData.name,
          departamento: store.state.userData.permission,
        }),
      });

      if (response.ok) {
        apagarTudo();
        toast.add({
          severity: 'success',
          summary: 'Enviado!',
          detail: 'Confira os resultados dos disparos no Analytics',
          life: 2000,
        });
      } else {
        throw new Error('Erro ao enviar e-mails.');
      }
    } catch (error) {
      toast.add({
        severity: 'error',
        summary: 'Erro',
        detail: 'Erro ao enviar e-mails. Por favor, tente novamente mais tarde.',
        life: 5000,
      });
    } finally {
      isSending.value = false;
    }
  };

  const apagarTudo = () => {
    const destinatariosAdicionadoRef = ref(null);
    const emailsInputRef = ref(null);

    if (destinatariosAdicionadoRef.value && typeof destinatariosAdicionadoRef.value.clearData === 'function') {
      destinatariosAdicionadoRef.value.clearData();
    }

    if (emailsInputRef.value && typeof emailsInputRef.value.clearData === 'function') {
      emailsInputRef.value.clearData();
    }

    recipientList .value = '';
    remetenteSelecionado.value = '';
    templateSelecionado.value = '';
    fileData.value = '';
    listName.value = '';
    mostrarEmailsInput.value = true;
    mostrarEmails.value = true;
    list.value = false;
    listBanco.value = false;
    dadosBanco.value = [];
    showTemplateSelector.value = false;
  };
</script>

<style scoped>
 .dark-theme .passoCinco{
    color: white;
  }
</style>