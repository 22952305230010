<template>
    <div class="cadastrarUsuarios w-full flex gap-10">
        <div>
            <h2>Cadastrar Novo Usuário</h2>
        </div>
        <form ref="form" @submit.prevent="cadastrarUsuario">
            <input type="text" id="name" placeholder="Nome" v-model="usuario.name" required>
            
            <input type="email" id="email" placeholder="Email" v-model="usuario.email" required>
            
            <input type="password" id="password" placeholder="Senha" v-model="usuario.password" required>

            <label for="permission" class="permission block font-bold">Permissões</label>
            <select name="permission" id="permission" v-model="usuario.permission" class="w-full px-3 py-2 rounded-lg border focus:outline-none">
                <option value="1">Crediario</option>
                <option value="2">Auxiliar Crediario</option>
                <option value="3">Marketing</option>
                <option value="4">Auxiliar Marketing</option>
                <option value="5">Admin</option>
            </select>
            <button type="submit">Cadastrar</button>
        </form>
    </div>  
</template>

<script>
    export default{
        name:'CadastroUsuario',
        data(){
            return{
                usuario:{
                    name: '',
                    email: '',
                    password: '',
                    permission: '1'
                }
            }
        },
        methods:{
            async cadastrarUsuario() {
                try {
                    const response = await fetch(`${process.env.VUE_APP_BASE_URL}/signup`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(this.usuario)
                    });

                    const responseData = await response.json();

                    if (response.ok) {
                        alert(`Usuário ${this.usuario.name} cadastrado com sucesso`);
                        this.$refs.form.reset();
                    
                        this.$emit('usuario-cadastrado');
                    } else if (response.status === 400) {
                        alert(`[Erro] ${responseData.error}`);
                        console.log(responseData.error)
                        this.$refs.form.reset();
                    } else {
                        throw new Error('Erro ao cadastrar usuário');
                    }
                } catch (error) {
                    console.error('Erro ao cadastrar usuário:', error);
                    alert('Erro ao cadastrar usuário. Por favor, tente novamente mais tarde.');
                }
            }   
        }
    }
</script>

<style scoped>
    .cadastrarUsuarios {
        flex-direction: column;
        padding: 20px;
        border-left: 2px solid #038D38;
    
    }

    .cadastrarUsuarios form {
        display: grid;
        gap: 10px;

    }

    .light-theme .cadastrarUsuarios h2 {
        font-size: 24px;
    
    }

    .light-theme .cadastrarUsuarios label {
        font-size: 16px;
        color: #000000;

    }

    .light-theme .cadastrarUsuarios input[type="text"],
    .light-theme .cadastrarUsuarios input[type="email"],
    .light-theme .cadastrarUsuarios input[type="password"] {
        width: 100%;
        padding: 10px;
        border: 2px solid #038D38;
        border-radius: 4px;
        font-size: 16px;
    }

    .light-theme .cadastrarUsuarios button[type="submit"] {
        width: 100%;
        padding: 10px;
        background-color: #038D38;
        color: #fff;
        border: none;
        border-radius: 4px;
        font-size: 16px;
        cursor: pointer;
        transition: background-color 0.3s ease;
    }

    .light-theme  .cadastrarUsuarios button[type="submit"]:hover {
        background-color: #03b447;
    }

    .dark-theme .cadastrarUsuarios h2 {
        font-size: 24px;
    
    }

    .dark-theme .cadastrarUsuarios label {
        font-size: 16px;
        color: #000000;

    }

    .dark-theme .cadastrarUsuarios input[type="text"],
    .dark-theme .cadastrarUsuarios input[type="email"],
    .dark-theme .cadastrarUsuarios input[type="password"] {
        width: 100%;
        padding: 10px;
        border: 2px solid #015220;
        border-radius: 4px;
        font-size: 16px;
    }

    .dark-theme .cadastrarUsuarios button[type="submit"] {
        width: 100%;
        padding: 10px;
        background-color: #015220;
        color: #fff;
        border: none;
        border-radius: 4px;
        font-size: 16px;
        cursor: pointer;
        transition: background-color 0.3s ease;
    }

    .dark-theme  .cadastrarUsuarios button[type="submit"]:hover {
        background-color: #03b447;
    }

</style>