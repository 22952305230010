<template>
  <div class="rounded-md border-2 overflow-hidden criarTemplate"> 
    <div class=" flex flex-col justify-end">
      <form class="flex flex-col  justify-end gap-2 p-4">
        <div class="mt-3">
          <FloatLabel>
            <InputText 
              v-model="templateName"
              type="text"
              class="w-full border rounded-md"
              @input="validateTemplateName"    
            />
            <label for="templateName">Nome do Template</label>
          </FloatLabel>
        </div>

        <div
          v-if="templateNameError" 
          class="messageError text-sm"
        >
          {{ templateNameError }}
        </div>

        <div class="mt-4">
          <FloatLabel>
            <InputText 
              v-model="templateSubject" 
              maxlength="40"
              class="w-full border rounded-md"
            />
            <label for="templateSubject">Assunto</label>
          </FloatLabel> 
          <div class="text-right text-sm text-green-600 font-semibold break-words">
            até {{ caracteresRestantesSubject }} caracteres
          </div>
        </div>

        <div>
          <FloatLabel>
            <Textarea 
              v-model="templateDescription" 
              maxlength="105"
              auto-resize
              class="custom-textarea"
            />
            <label for="templateDescription">Pré-Descrição</label>
          </FloatLabel>
          <div class="text-right text-sm text-green-600 font-semibold break-words">
            até {{ caracteresRestantes }} caracteres
          </div>
        </div>

        <div class="mt-3">
          <FloatLabel>
            <InputText 
              v-model="templateLink"
              type="text"
              class="w-full border rounded-md"
              @input="validateTemplateName"    
            />
            <label for="templateLink">Link da promoção</label>
          </FloatLabel>
        </div>

        <div class="card">
          <FileUpload 
            ref="fileUploadRef" 
            accept=".webp" 
            :max-file-size="maxFileSize"
            file-thumbnail="Tipo de arquivo não suportado. Por favor, faça o upload de arquivos no formato .webp."
            invalid-file-type-message="Tipo do arquivo invalido,certifique-se que e .webp"
            invalid-file-size-message="Arquivo muito grande!"
            @select="onSelectedFiles" 
          >
            <template #header="{ chooseCallback }">
              <div class="flex flex-wrap justify-between items-center flex-1 gap-4">
                <div class="flex gap-2">
                  <PrimeButton 
                    icon="pi pi-images" 
                    rounded 
                    outlined 
                    class="iconeImagem"
                    severity="secondary" 
                    @click="chooseCallback" 
                  />
                  <PrimeButton 
                    icon="pi pi-times" 
                    rounded 
                    outlined 
                    class="iconeX"
                    severity="danger" 
                    :disabled="!templateFile"
                    @click="clear" 
                  />
                </div>
                <ProgressBar 
                  :value="totalSizePercent" 
                  :show-value="false" 
                  class="md:w-20rem h-1 w-full md:ml-auto"
                />
              </div>
            </template>

            <template #content>
              <div 
                v-if="templateFile"
                class="flex flex-col items-center" 
              >
                <div class="flex flex-col items-center gap-4">
                  <div class="card flex justify-center">
                    <Image 
                      role="presentation" 
                      :alt="templateFile.name" 
                      :src="templateFile.objectURL"
                      width="250" 
                      class="imagem" 
                      preview
                    />
                  </div>
                  <span class="font-semibold text-ellipsis max-w-xs whitespace-nowrap overflow-hidden">{{ templateFile.name }}</span>
                  <div>Tamanho do Arquivo {{ formatSize(templateFile.size) }}</div>
                </div>
              </div>
            </template>

            <template #empty>
              <div class="flex items-center justify-center flex-col">
                <i class="pi pi-cloud-upload !border-2 !rounded-full !p-8 !text-4xl !text-muted-color" />
                <p class="mt-6 mb-0">
                  Arraste e solte os arquivos aqui para fazer upload.
                </p>
              </div>
            </template>
          </FileUpload>
        </div>

        <div class="flex flex-col sm:flex-row gap-2 justify-center">
          <div>     
            <div class="card flex justify-center">
              <PrimeButton 
                type="button" 
                severity="success" 
                label="Criar Template" 
                :disabled="!isFormValid" 
                @click="requireConfirmation()" 
              />
            </div>
          </div>
      
  
          <div class="card flex flex-wrap gap-2 justify-center">
            <PrimeButton
              type="button"
              severity="danger"
              label="Cancelar"
              class="p-button-warning sm:w-auto px-2 py-1"
              @click="confirmCancel($event)" 
            />
          </div>

          <div class="card">
            <div class="flex gap-2 justify-center">
              <PrimeButton 
                v-tooltip="'Ajuda'"
                type="button"
                class="botaoDeAjuda"
                label="Ajuda"
                icon="pi pi-question-circle"
                icon-pos="right" 
                @click="visibleRight = true"
              />
            </div>
          </div>
        </div>
      </form>
    </div>

    <ConfirmDialog 
      group="headless"
    >
      <template 
        #container="{ message, acceptCallback, rejectCallback }"
      >
        <div 
          class="flex flex-col items-center p-8 bg-surface-0 dark:bg-surface-900 rounded"
        >
          <span 
            class="font-bold text-2xl block mb-2 mt-6"
          >
            {{ message.header }}
          </span>
          <p 
            class="mb-0"
          >
            {{ message.message }}
          </p>
          <div class="flex items-center gap-2 mt-6">
            <PrimeButton 
              severity="success"
              label="Criar" 
              @click="acceptCallback" 
            />
            <PrimeButton
              severity="danger" 
              label="Cancelar" 
              @click="rejectCallback"
            />
          </div>
        </div>
      </template>
    </ConfirmDialog>

    <Drawer 
      v-model:visible="visibleRight"
      header="Precisando de ajuda?" 
      position="right"
    >
      <p> informações </p>
    </Drawer>
  </div>
</template>

<script setup>
  import { ref, computed, defineEmits} from 'vue';
  import { useToast } from 'primevue/usetoast';
  import { useConfirm } from "primevue/useconfirm";
  import FloatLabel from 'primevue/floatlabel';
  import InputText from 'primevue/inputtext';  
  import Textarea from 'primevue/textarea';
  import FileUpload from 'primevue/fileupload';
  import ProgressBar from 'primevue/progressbar';
  import Image from 'primevue/image';
  import Drawer from 'primevue/drawer';
 import ConfirmDialog from 'primevue/confirmdialog';

  const emit = defineEmits(['cancelandoCriacao','templateCriado']);
  const toast = useToast();
  const confirm = useConfirm();
  
  const visibleRight = ref(false);
  const totalSizePercent = ref(0);
  const maxFileSize = 716800; 
  const totalSize = ref(0);
  const fileUploadRef = ref(null); 
  
  let templateName = ref('');
  let templateSubject = ref('');
  let templateDescription = ref('');
  let templateLink = ref('');
  let templateNameError = ref('');
  let templateFile = ref(null);
  
  const validateTemplateName = () => {
    const regex = /^[a-zA-Z0-9\s]*$/;

    if (!regex.test(templateName.value)){
      templateNameError.value = 'O nome da promoção não deve conter acentuação ou pontos!';
    }else{
      templateNameError.value = ''
    }
  };

  const clear = () => {
    templateFile.value = null;  
    totalSize.value = 0;       
    totalSizePercent.value = 0;
    
    if (fileUploadRef.value && fileUploadRef.value.clear) {
      fileUploadRef.value.clear(); 
    }
  };

  const onSelectedFiles = (event) => {
    if (templateFile.value) {
      toast.add({ 
        severity: 'warn', 
        summary: 'Atenção', 
        detail: 'Você só pode carregar um arquivo.',  
        group: 'br',
        life: 2000 
      });
      return;
    }

    if (event.files.length > 0) {
      const file = event.files[0];
      templateFile.value = file;
      totalSize.value = file.size;
      totalSizePercent.value = 100; 
    } else {
      toast.add({ 
        severity: 'warn', 
        summary: 'Atenção', 
        detail: 'Verifique o Arquivo, ele pode não está selecionado no formato webp ou maior que 700kb.',  
        group: 'br', 
      });
    }
  };

  const formatSize = (bytes) => {
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB'];
    if (bytes === 0) return '0 Byte';
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  };

  const isFormValid = computed(() => templateName.value && templateLink.value && templateSubject.value && templateFile.value && !templateNameError.value )

  const caracteresRestantesSubject = computed(() => 40 - templateSubject.value.length);
  const caracteresRestantes = computed(() => 105 - templateDescription.value.length);

  const requireConfirmation = () => {
    confirm.require({
      group: 'headless',
      header: 'Tem certeza?',
      message: 'Por favor, confirme para Criar Template.',
      accept: () => {
        enviarTemplate()
        toast.add({ severity: 'success', summary: 'Template Criado', life: 2000 });
      },
      reject: () => {
        toast.add({ severity: 'info', summary: 'Cancelar Template?', detail: 'corrija os dados ou clique em cancelar para escolher um template ja criado. ', life: 3000 });
          
      }
    });
  };

  const confirmCancel = (event) => {
    confirm.require({
      target: event.currentTarget,
      message: 'O template não será salvo', 
      icon: 'pi pi-exclamation-triangle',
      rejectProps: {
        label: 'Sim,cancelar',
        severity: 'secondary',
        outlined: true
      },
      acceptProps: {
        label: 'Não,cancelar'
      },
      reject: () => {
        resetarFormulario()
        toast.add({ severity: 'warn', summary: 'Criação de template cancelado', life: 2000 });
        emit('cancelandoCriacao');
      }
    });
  };

  const enviarTemplate = async () => { 
    try {
      const formData = new FormData();
      formData.append('name', templateName.value);
      formData.append('subject', templateSubject.value);
      formData.append('description', templateDescription.value);
      formData.append('link', templateLink.value);
      const fileName = `${templateName.value}.${templateFile.value.name.split('.').pop()}`;
      formData.append('file', templateFile.value, fileName);

      const response = await fetch(`${process.env.VUE_APP_BASE_URL}/credit/createTemplate-crediario`, {
        method: 'POST',
        body: formData
      });

      if (!response.ok) {
        throw new Error('Erro ao salvar o template');
      }

      await response.json();
      resetarFormulario()
      emit('templateCriado');
    } catch (error) {
      console.error('Erro ao enviar o template:', error);
    }
  };

  const resetarFormulario = () => {
    templateName = '';
    templateLink = '';
    templateSubject = '';
    templateDescription = '';
    templateFile = null;
    templateNameError = '';
  }
</script>

<style scoped>
  .criarTemplate{
    width: 500px;
    height: 100%;
  }


  .light-theme .messageError{
    color: red;
  }

  .light-theme .botaoDeAjuda,
  .dark-theme .botaoDeAjuda {
    background-color: rgb(192, 192, 192);
    border-color:rgb(192, 192, 192);
  }

  .light-theme .botaoDeAjuda:hover,
  .dark-theme .botaoDeAjuda:hover{
    background-color: rgba(114, 114, 114, 0.685);
    border-color:rgba(114, 114, 114, 0.685);
  }

  .imagem{
    width: 100px;
    height: 200px;
  }

  .custom-textarea {
    width: 100%; 
    max-width: 100%; 

  }

  .dark-theme .messageError{
    color: red;
  }

  .dark-theme .criarTemplate{
    border-color:#015220 !Important;
  }
  
  .dark-theme .p-button-label{
    color: black;
  }
   
  @media (max-width: 600px) {
    .custom-textarea {
      width: 100%; 
      font-size: 14px; 
    }
  }
</style>