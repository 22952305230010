<template>
  <div 
    v-if="selectedTemplate" 
    id="templateDetails" 
    class="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75 transition-opacity duration-300"
  >
    <div class="w-full max-w-4xl p-6 border rounded-lg bg-white shadow-lg transform transition-transform duration-300 overflow-y-auto max-h-full relative">
      <div class="botao">
        <PrimeButton 
          icon="pi pi-times" 
          class="p-button-rounded p-button-danger"
          @click="closeTemplateDetails" 
        />
      </div>

      <h2 class="text-2xl text-center font-semibold mb-6">
        Promoção: {{ selectedTemplate.templateName }}
      </h2>

      <div v-if="chartsData.labels.length > 0">
        <p class="text-lg font-semibold mb-4">
          Dados da Promoção
        </p>
        <Chart 
          ref="promotionChart" 
          type="bar" 
          :data="chartsData" 
          :options="chartOptions" 
          class="w-full h-96" 
        />
      </div>

      <div>
        <div class="flex justify-center items-center p-1 ">
          <PrimeButton
            :label="showSection ? 'Menos detalhes' : 'Mais detalhes'"
            severity="info"
            @click="toggleSection"
          />
        </div>
        <div v-if="showSection">
          <div class="mt-1">
            <p 
              class="text-lg font-semibold mb-1 cursor-pointer" 
              @click="toggleEmailsAbertos">
              Emails Abertos - {{ selectedTemplate.totalAberturas }}
            </p>

            <div 
              v-if="showEmailsAbertos"
              class="border-2 rounded-md" 
            >
              <ul class="list-disc ml-4 p-6">
                <li 
                  v-for="(email, index) in emailsAbertos" 
                  :key="index" 
                >
                  <p>{{ email }}</p>
                </li>
              </ul>
            </div>
          </div>

          <div class="mt-1">
            <p 
              v-if="selectedTemplate.clicksTotal.total > 0" 
              class="text-lg font-semibold mb-1 cursor-pointer"
              @click="toggleClicksDetails" 
            >
              Clicks Totais - {{ selectedTemplate.clicksTotal.total }}
            </p>
            <ul 
              v-if="showClicksDetails && Object.keys(filteredClicksTotal).length > 0" 
              class="list-disc ml-4 border-2 rounded-md p-6"
            >
              <li 
                v-for="(category, label) in filteredClicksTotal" 
                :key="label" 
                class="cursor-pointer "
                @click="toggleCategory(label)" 
              >
                {{ label }}: {{ category }}
                <ul 
                  v-if="expandedCategories[label]" 
                  class=" ml-4"
                >
                  <li 
                    v-for="email in emailClicksByCategory[label]" 
                    :key="email"
                  >
                    <p>{{ email }}</p>
                  </li>
                </ul>
              </li>
            </ul>
          </div>

          <div 
            v-if="selectedTemplate.cancelamento.total > 0"
            class="mt-1" 
          >
            <p 
              class="text-lg font-semibold cursor-pointer" 
              @click="toggleCancelamentos"
            >
              Emails Cancelados - {{ selectedTemplate.cancelamento.total }}
            </p>
            <div v-if="showCancelamentos">
              <ul class="list-disc ml-4 border-2 rounded-md p-6">
                <li 
                  v-for="(email, index) in selectedTemplate.cancelamento.email" 
                  :key="index" 
                  class="mt-2"
                >
                  <p><strong>Email:</strong> {{ email }}</p>
                  <p><strong>Motivo:</strong> {{ selectedTemplate.cancelamento.motivo[index] }}</p>
                </li>
              </ul>
            </div>
          </div>

          <div class="mt-1">
            <p 
              class="text-lg font-semibold mb-1 cursor-pointer" 
              @click="toggleDisparos"
            >
              Disparos - {{ disparosData.length }}
            </p>
            <div 
              v-if="showDisparos && disparosData.length > 0"
              class="border-2 rounded-md p-6" 
            >
              <ul class="list-disc ml-4">
                <li 
                  v-for="disparo in disparosData" 
                  :key="disparo.date" 
                  class="mt-2"
                >
                  <p><strong>Data:</strong> {{ new Date(disparo.date).toLocaleString() }}</p>
                  <p><strong>Usuário:</strong> {{ disparo.user }}</p>
                  <p><strong>Departamento:</strong> {{ getDepartamentoName(disparo.departamento) }}</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div> 
  </div>
</template> 

<script setup>
  import { defineProps, defineEmits, ref, computed } from 'vue';
  import Chart from 'primevue/chart';

  const emit = defineEmits(['close', 'toggle-emails-opened', 'toggle-clicks-details', 'toggle-category']);

  const props = defineProps({
    selectedTemplate: {
      type: Object,
      required: true
    },
    showEmailsOpened: {
      type: Boolean,
      default: false
    },
    showClicksDetails: {
      type: Boolean,
      default: false
    },
    expandedCategories: {
      type: Object,
      default: () => ({})
    }
  });

  const promotionChart = ref(null);

  const showSection = ref(false);
  const showEmailsAbertos = ref(false);
  const showDisparos = ref(false);
  const showCancelamentos = ref(false);

  const chartsData = computed(() => {
    const { clicksTotal, sistemasOperacionais, totalAberturas, totalDisparos } = props.selectedTemplate;

    // Preparar dados
    const soLabels = Object.keys(sistemasOperacionais).filter(key => sistemasOperacionais[key] !== 0 && sistemasOperacionais[key] !== null && sistemasOperacionais[key] !== undefined);
    const soData = soLabels.map(key => sistemasOperacionais[key]);

    const clickLabels = Object.keys(clicksTotal).filter(key => clicksTotal[key] !== 0 && clicksTotal[key] !== null && clicksTotal[key] !== undefined);
    const clickData = clickLabels.map(key => clicksTotal[key]);

    // Garantir que os labels "Total de Disparos" e "Total de Aberturas" estão no gráfico
    const labels = ['Total de Disparos', 'Total de Aberturas', ...soLabels, ...clickLabels];

    return {
      labels,
      datasets: [
        {
          label: 'Total de Disparos',
          backgroundColor: '#42A5F5',
          data: [totalDisparos, 0, ...soLabels.map(() => 0), ...clickLabels.map(() => 0)]
        },
        {
          label: 'Total de Aberturas',
          backgroundColor: '#66BB6A',
          data: [0, totalAberturas, ...soLabels.map(() => 0), ...clickLabels.map(() => 0)]
        },
        {
          label: 'Clicks',
          backgroundColor: '#FF7043',
          data: [0, 0, ...soLabels.map(() => 0), ...clickData]
        },
        {
          label: 'Clicks por Sistema Operacional',
          backgroundColor: '#FFA726',
          data: [0, 0, ...soData, ...clickLabels.map(() => 0)]
        }
      ]
    };
  });

  const emailsAbertos = computed(() => {
    return props.selectedTemplate.emailClicks.map(click => click.email) || [];
  });

  const disparosData = computed(() => {
    return props.selectedTemplate.disparos || [];
  });

  const emailClicksByCategory = computed(() => {
    const clicksByCategory = {};
    Object.keys(props.expandedCategories).forEach(category => {
      clicksByCategory[category] = props.selectedTemplate.emailClicks
        .filter(click => click[category] > 0)
        .map(click => click.email);
    });
    return clicksByCategory;
  });

  const filteredClicksTotal = computed(() => {
    const clicksTotal = props.selectedTemplate.clicksTotal || {};
    const filtered = {};
    Object.keys(clicksTotal).forEach(label => {
      if (clicksTotal[label] > 0 && label !== 'total') {
        filtered[label] = clicksTotal[label];
      }
    });
    return filtered;
  });

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false
  };

  const closeTemplateDetails = () => {
    emit('close');
  };

  const toggleSection = () => {
    showSection.value = !showSection.value;
  };

  const toggleEmailsAbertos = () => {
    showEmailsAbertos.value = !showEmailsAbertos.value;
  };

  const toggleDisparos = () => {
    showDisparos.value = !showDisparos.value;
  };

  const toggleClicksDetails = () => {
    emit('toggle-clicks-details');
  };

  const toggleCategory = (category) => {
    emit('toggle-category', category);
  };

  const toggleCancelamentos = () => {
    showCancelamentos.value = !showCancelamentos.value;
  };

  const getDepartamentoName = (departamento) => {
    const departamentos = {
      2: 'Crediario',
      3: 'Marketing',
      5: 'Admin'
    };
    return departamentos[departamento] || 'Desconhecido';
  };
</script>

<style scoped>
  #templateDetails {
    backdrop-filter: blur(5px);
  }
  
  .chart-container {
    height: 24rem;
  }
  
  .cursor-pointer {
    cursor: pointer;
  }
  
  .botao {
    position: absolute;
    right: 16px; 
    top: 16px; 
  }
  
  .list-disc {
    list-style-type: disc;
  }
</style>