<template>
  <div class="flex flex-col items-center justify-center">
    <div class="flex justify-center">
      <p class="font-bold text-white text-lg p-1 text-center">
        4° Crie uma <strong class="text-red-600">"NOVA LISTA"</strong> ou <strong class="text-red-600">"BUSQUE UMA LISTA"</strong> já criada!
      </p>
    </div>
    <div class="flex gap-2 w-full items-center justify-center">
      <PrimeButton
        label="Criar lista" 
        icon="pi pi-plus" 
        class="p-button-success" 
        @click="createList"
      />

      <PrimeButton
        label="Buscar lista"
        severity="info"
        @click="fetchList" 
      />

      <PrimeButton 
        label="Voltar a Seleção de Templates"
        severity="warn"
        @click="voltarTemplate"
      />

      <PrimeButton 
        v-tooltip="'Ajuda'"
        type="button"
        class="botaoDeAjuda"
        label="Ajuda"
        icon="pi pi-question-circle"
        icon-pos="right" 
        @click="visibleRight = true"      
      />
    </div>
    <Drawer 
      v-model:visible="visibleRight"
      header="Precisando de ajuda?" 
      position="right"
    >
      <p> informações </p>
    </Drawer>
  </div>
</template>

<script setup>
  import { ref, defineEmits } from 'vue';
  import Drawer from 'primevue/drawer';

  const visibleRight = ref(false);
  const emit = defineEmits(['createList', 'fetchList', 'voltarTemplate']);

  const createList = () => {
    emit('createList');
  };

  const fetchList = () => {
    emit('fetchList');
  };

  const voltarTemplate = () =>{
    emit('voltarTemplate')
  }
</script>

<style scoped>

  .light-theme .botaoDeAjuda,
  .dark-theme .botaoDeAjuda {
    background-color: rgb(192, 192, 192);
    border-color:rgb(192, 192, 192);
  }

  .light-theme .botaoDeAjuda:hover,
  .dark-theme .botaoDeAjuda:hover{
    background-color: rgba(114, 114, 114, 0.685);
    border-color:rgba(114, 114, 114, 0.685);
  }

</style>