<template>
  <div class="flex flex-col gap-2 w-full p-2">
    <div v-if="error" class="error">
      {{ error }}
    </div>
    <div v-else-if="loading" class="loading">
      Carregando...
    </div>
    <div v-else class="w-full selectPromocoes">
      <Select
        v-model="selectedListId" 
        class="w-full"
        filter
        :options="listas"
        option-label="listaNome"
        option-value="_id"
        placeholder="Selecione uma lista"
      />
    </div>
    <PrimeButton
      label="Voltar"
      severity="warn"
      @click="voltar"
    />
  </div>
</template>

<script setup>
  import { ref, onMounted, defineEmits, watch } from 'vue';
  import Select from 'primevue/select';

  const emit = defineEmits(['lista-selecionada', 'voltar']);
  const listas = ref([]);
  const selectedListId = ref(null);  
  const selectedList = ref(null);  
  const loading = ref(true);
  const error = ref(null);

  const fetchListas = async () => {
    try {
      const response = await fetch(`${process.env.VUE_APP_BASE_URL}/whats/lists`);
      if (!response.ok) {
        throw new Error('Erro ao buscar listas');
      }
      const data = await response.json();
      listas.value = data;
    } catch (err) {
      error.value = 'Erro ao buscar listas.';
      console.error(err);
    } finally {
      loading.value = false;
    }
  };


  watch(selectedListId, (newId) => {
    selectedList.value = listas.value.find(lista => lista._id === newId);
    if (selectedList.value) {
      emit('lista-selecionada', selectedList.value);
    }
  });

  const voltar = () => {
    emit('voltar');  
  };

  onMounted(fetchListas);
</script>

<style>
  .error {
    color: red;
  }
  
  .loading {
    font-style: italic;
  }

  .light-theme .selectPromocoes{
    width: 300px;
  }
</style>
  