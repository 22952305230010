<template>
  <div 
    class="flex flex-col min-h-screen w-screen"
  >
    <HeaderTemplate 
      :current-step="currentStep"
    />

    <div 
      class="flex flex-col items-center justify-center flex-grow gap-1 relative "
    >
      <Carousel 
        class="w-full p-6 transition-opacity duration-1000 ease-in-out fade-in "
        :num-visible="adjustedNumVisibleItems" 
        :autoplay-interval="4000"
        :num-scroll="3"
        orientation="horizontal" 
        responsive
        aria-live="polite"
        :circular="isCircular"
        :class="{'highlight': currentStep === 3, 'z-10': currentStep === 3}"
        :value="filteredInfoBoxes" 
        :indicators="true" 
      >
        <template 
          #item="slotProps"
        >
          <div 
            :class="[
              'p-6 h-80 w-full flex flex-col transition-transform duration-3000 ease-in-out transform hover:scale-105 hover:bg-opacity-75 rounded-lg', 
              { 'pointer-events-none': currentStep === 3 }
            ]"
            tabindex="0"
          >
            <InfoBox
              class="w-full h-full flex flex-col justify-center items-center transition-opacity duration-1000 ease-in-out"
              :title="slotProps.data.title"
              :description="slotProps.data.description"
              :gradient="slotProps.data.gradient"
              :border-color="slotProps.data.borderColor"
              :link="slotProps.data.link"
              aria-describedby="'infoBox-description-' + slotProps.index"
            />
          </div>
        </template>
      </Carousel>
    </div>

    <div 
      v-if="showModal && currentStep === 0" 
      class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 transition-opacity duration-700"
    >
      <div 
        class="bg-white p-6 rounded-lg shadow-lg transition-transform duration-700 slide-left tutorial"
      >
        <h2 
          class="text-xl font-bold mb-4"
        >
          {{ tutorialSteps[currentStep].title }}
        </h2>
        <p 
          class="mb-4"
        >
          {{ tutorialSteps[currentStep].content }}
        </p>
        <div 
          class="flex justify-between"
        >
          <button 
            v-if="currentStep < tutorialSteps.length - 1" 
            class="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-700"
            @click="nextStep" 
          >
            Ver Tutorial
          </button>
          <button 
            class="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-700"
            @click="endTutorial" 
          >
            {{ currentStep === tutorialSteps.length - 1 ? 'Concluir' : 'Pular Tutorial' }}
          </button>
        </div>
      </div>
    </div>

    <div 
      v-if="currentStep === 1" 
      class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
    >
      <div 
        :class="['absolute top-20 left-1/2 transform -translate-x-1/2 bg-white p-4 rounded shadow-lg slideInFromTop tooltip-step step1 tutorial']"
      >
        <h2 
          class="text-xl text-center font-bold mb-4"
        >
          {{ tutorialSteps[currentStep].title }}
        </h2>
        <p
          class="text-justify"
        >
          {{ tutorialSteps[currentStep].content }}
        </p>
        <div 
          class="flex justify-between mt-4"
        >
          <button 
            v-if="currentStep > 0" 
            class="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-700"
            @click="prevStep" 
          >
            Anterior
          </button>
          <button 
            class="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-700"
            @click="nextStep" 
          >
            Próximo
          </button>
        </div>
      </div>
    </div>

    <div 
      v-if="currentStep === 2" 
      class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
    >
      <div 
        :class="['absolute top-20 transform -translate-x-1/2 bg-white p-4 rounded fade-in shadow-lg tooltip-step step2']"
      >
        <h2 
          class="text-xl text-center font-bold mb-4"
        >
          {{ tutorialSteps[currentStep].title }}
        </h2>
        <p 
          class="text-center"
        >
          {{ tutorialSteps[currentStep].content }}
        </p>
        <div 
          class="flex justify-between mt-4"
        >
          <button 
            v-if="currentStep > 0" 
            class="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-700"
            @click="prevStep" 
          >
            Anterior
          </button>
          <button 
            class="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-700"
            @click="nextStep" 
          >
            Próximo
          </button>
        </div>
      </div>
    </div>

    <div 
      v-if="currentStep === 3" 
      class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
    >
      <div 
        :class="['fixed bottom-8 bg-white p-4 rounded shadow-lg tooltip-step tutorial slide-rigth']"
      >
        <h2 
          class="text-xl text-center font-bold mb-4"
        >
          {{ tutorialSteps[currentStep].title }}
        </h2>
        <p 
          class="text-justify"
        >
          {{ tutorialSteps[currentStep].content }}
        </p>
        <div 
          class="flex justify-between mt-4"
        >
          <button 
            v-if="currentStep > 0" 
            class="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-700"
            @click="prevStep" 
          >
            Anterior
          </button>
          <button 
            v-if="currentStep > 0" 
            class="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-700"
            @click="endTutorial" 
          >
            Finalizar Tutorial
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { computed, ref, onMounted, onUnmounted } from 'vue';
  import { useStore } from 'vuex';
  import InfoBox from '../components/homePage/infoBox';
  import Carousel from 'primevue/carousel';

  const store = useStore();
  const userData = computed(() => store.state.userData);

  const maxVisibleItems = ref(3);
  const showModal = ref(true);
  const currentStep = ref(0);
  const TUTORIAL_SEEN_KEY = 'tutorialSeen';

  const tutorialSteps = [
    {
      title: `Seja Bem-vindo, ${userData.value.name}!`,
      content: 'Em cada página, você encontrará um pequeno tutorial para conhecer todas as funcionalidades que nossa plataforma de marketing oferece.😊',
    },
    {
      title: 'Barra de Navegação',
      content: 'Esta é a barra de navegação.\n Aqui, você pode acessar todos os módulos da plataforma.',
    },
    {
      title: 'Usuário',
      content: `Clique e altere\nTemas , Permissões\ne Configurações do usuário`,
    },
    {
      title: 'Modal',
      content: 'Aqui você tem uma breve descrição do que cada módulo faz e acesso ao Suporte caso precise relatar Bugs ou Ajuda.',
    }
  ];

  const filteredInfoBoxes = computed(() => {
    return infoBoxes.filter(box => box.permission.includes(userData.value.permission));
  });

  const adjustedNumVisibleItems = computed(() => {
    return Math.min(filteredInfoBoxes.value.length, maxVisibleItems.value);
  });

  const isCircular = computed(() => filteredInfoBoxes.value.length > adjustedNumVisibleItems.value);

  const updateMaxVisibleItems = () => {
    const width = window.innerWidth;
    if (width <= 768) {
      maxVisibleItems.value = 3;
    } else if (width <= 1024) {
      maxVisibleItems.value = 3;
    } else {
      maxVisibleItems.value = 3;
    }
  };

  const nextStep = () => {
    if (currentStep.value < tutorialSteps.length - 1) {
      currentStep.value++;
    }
  };

  const prevStep = () => {
    if (currentStep.value > 0) {
      currentStep.value--;
    }
  };

  const endTutorial = () => {
    showModal.value = false;
    currentStep.value = 0;
    localStorage.setItem(TUTORIAL_SEEN_KEY, 'true');
  };

  onMounted(() => {
    updateMaxVisibleItems();
    window.addEventListener('resize', updateMaxVisibleItems);

    if (localStorage.getItem(TUTORIAL_SEEN_KEY) === 'true') {
      showModal.value = false;
    } else {
      showModal.value = true;
    }
  });

  onUnmounted(() => {
    window.removeEventListener('resize', updateMaxVisibleItems);
  });

  const infoBoxes = [
    {
      title: "Email Crediário",
      description: "Emails automatizados para facilitar o processo de renegociação de débitos de forma eficiente e segura.",
      link: { name: 'EmailCrediarioPage' },
      borderColor: "border-2 border-blue-500 custom-shadowBlue",
      gradient: "bg-blue-600",
      permission: [1, 2, 3, 5]
    },
    {
      title: "Suporte",
      description: "Para obter suporte técnico ou relatar bugs (erros), entre em contato com nosso departamento de suporte através do WhatsApp.",
      borderColor: "border-2 border-black custom-shadowBlack", 
      gradient: "bg-black", 
      link: "https://wa.me/5563991029578?text=Ol%C3%A1%2C+Suporte+Técnico+de+Desenvolvimento",
      permission: [1, 2, 3, 4, 5]
    },
    {
      title: "Iframes",
      description: "Iframes personalizados desenvolvidos para os produtos do site Lojas Nosso Lar.",
      borderColor: "border-2 border-green-500 custom-shadowGreen",
      gradient: "bg-green-500",
      link: { name: 'iframes' },
      permission: [3, 4, 5]
    },
    {
      title: "Email Marketing",
      description: "Envie campanhas de email altamente personalizadas e eficazes, alcançando um grande número de destinatários.",
      borderColor: "border-2 border-yellow-500 custom-shadowYellow",
      gradient: "bg-yellow-500",
      link: { name: 'emailMarketing' },
      permission: [3, 5]
    },
    {
      title: "Analytics Emails",
      description: "Mensuração de desempenho, engajamento e eficácia de campanhas de marketing.",
      borderColor: "border-2 border-purple-500 custom-shadowPurple",
      gradient: "bg-purple-500",
      link: { name:'analyticsMarketing'},
      permission: [3,5]
    },
    {
      title: "Usuários",
      description: "Registre, visualize e gerencie os usuários do sistema de forma simples e eficiente.",
      link: { name: 'UsuarioPagina' },
      borderColor: "border-2 border-red-500 custom-shadowRed",
      gradient: "bg-red-500",
      permission: [5]
    }
  ];
</script>

<style scoped>
  .tutorial{
    width: 450px;
  }

  .tooltip-step::before {
    content: '';
    position: absolute;
    bottom: 100%; 
    left: 50%;
    transform: translateX(-50%); 
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #ffffff;
    max-width: 400px;
    z-index: 1000;
  }

  .step1{
    top: 70px;
  }

  .step2{
    top: 70px;
    right: -110px;
  }

  .step2 p{
    white-space: pre-line;
  }

  .dark-theme .tutorial{
    background-color: rgb(19, 18, 18);
    box-shadow: 0px 3px 5px 0px rgba(255, 255, 255, 0.5), 0 2px 4px -5px rgba(128, 2, 201, 0.3);
  }

  .dark-theme .step2{
    background-color: rgb(19, 18, 18);
  }

  .dark-theme .tooltip-step{
    box-shadow: 0px 3px 5px 0px rgba(255, 255, 255, 0.5), 0 2px 4px -5px rgba(128, 2, 201, 0.3);
  }

  @keyframes slideInFromTop {
    0% {
      transform: translateY(-100%);
      opacity: 0;
    }
    50% {
      transform: translateY(0%)
  
    }
    100% {
      transform: translateX(-50%)
    
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes slideLeft {
    0% {
      transform: translateX(-100%);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
    }
  }

  @keyframes slideRigth {
    0% {
      transform: translateX(100%);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
    }
  }

  .slide-left {
    animation: slideLeft 1.5s cubic-bezier(0.25, 0.1, 0.25, 1.0);
  }

  .slide-rigth {
    animation: slideRigth 2.5s cubic-bezier(0.25, 0.1, 0.25, 1.0);
  }

  .fade-in {
    animation: fadeIn 1.5s cubic-bezier(0.25, 0.1, 0.25, 1.0);
  }

  .slideInFromTop {
    animation: slideInFromTop 2.5s cubic-bezier(0.25, 0.1, 0.25, 1.0);
  }

  .highlight {
    border: 2px solid #fffffe; 
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.5); 
    background-color: rgba(255, 255, 255, 0.1);
    transition: all 0.3s ease-in-out;
  }

  .p-carousel-item {
    flex: 1 0 100%;
  }

  .custom-shadowRed {
    box-shadow: 0 4px 10px -1px rgba(228, 3, 3, 0.781), 0 2px 4px -5px rgba(255, 0, 0, 0.3);
  }
  .custom-shadowGreen {
    box-shadow: 0 4px 10px -1px rgba(4, 255, 0, 0.5), 0 2px 4px -5px rgba(4, 255, 0, 0.3);
  }
  .custom-shadowYellow {
    box-shadow: 0 4px 10px -1px rgba(242, 255, 0, 0.5), 0 2px 4px -5px rgba(242, 255, 0, 0.3);
  }
  .custom-shadowBlue {
    box-shadow: 0 4px 10px -1px rgba(0, 106, 255, 0.5), 0 2px 4px -5px rgba(0, 106, 255, 0.3);
  }
  .custom-shadowBlack {
    box-shadow: 0 4px 10px -1px rgba(0, 0, 0, 0.5), 0 2px 4px -5px rgba(0, 0, 0, 0.3);
  }
  .custom-shadowPurple {
    box-shadow: 0 4px 10px -1px rgba(128, 2, 201, 0.5), 0 2px 4px -5px rgba(128, 2, 201, 0.3);
  }
  .custom-shadowBlack {
    box-shadow: 0 4px 10px -1px rgba(0, 0, 0, 0.5), 0 2px 4px -5px rgba(0, 0, 0, 0.3);
  }

  @media (min-width: 640px) {
    .p-carousel-item {
      flex: 1 0 50%;
    }
  }
  @media (min-width: 1024px) {
    .p-carousel-item {
      flex: 1 0 33.3333%;
    }
  }
</style>
