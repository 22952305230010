<template>
  <div class="w-full flex flex-col flex-grow animate-fadeIn">
    <div 
      v-if="!selectedRemetente" 
      class="w-full flex flex-col gap-1 p-4 items-center"
    >
      <div class="max-w-full">
        <h2 class="font-bold text-center w-full text-base break-words">
          1° Escolha o Remetente (Email que será usado para disparar)
        </h2>
      </div>
      <Select
        v-model="selectedRemetente" 
        placeholder="Selecione um remetente"
        :options="remetentes" 
        class="text-center font-bold text-lg border-2 rounded-md cursor-pointer w-full max-w-md transition"
        filter
        @change="remetenteSelecionado"
      />
    </div>
  </div>
</template>

<script setup>
  import { ref, onMounted, defineEmits } from 'vue';
  import Select from 'primevue/select';

  const emit = defineEmits(['remetenteSelecionado']);
  const remetentes = ref([]);
  const selectedRemetente = ref('');

  async function fetchRemetentes() {
    try {
      const response = await fetch(`${process.env.VUE_APP_BASE_URL}/email/senders`);
      const data = await response.json();
      remetentes.value = data.remetentes.map(remetente => remetente.user);

    } catch (error) {
      console.error('Erro ao buscar remetentes:', error);
    }
  }

  function remetenteSelecionado() {
    emit('remetenteSelecionado', selectedRemetente.value);
  }

  onMounted(fetchRemetentes);
</script>

<style>
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .animate-fadeIn {
    animation: fadeIn 1s ease-in-out;
  }

  select:focus {
    outline: none;
    transition: all 0.3s ease;
  }

  .dark-theme .p-select-option-label {
    color: black;
  }

</style>