<template>
  <div class="w-full flex flex-col gap-4 border-2 rounded-lg p-3">
    <div class="flex flex-col gap-1">
      <div class="card">
        <Editor 
          ref="tituloEditor" 
          v-model="titulo"
          editor-style="height: 50px"
          @input="handleTituloInput"
        >
          <template #toolbar>
            <p class="p-1">
              Titulo da Campanha
            </p>
            <span class="ql-formats">
              <button 
                v-tooltip.bottom="'Bold'" 
                class="ql-bold"
              />
              <button 
                v-tooltip.bottom="'Italic'" 
                class="ql-italic" 
              />
              <button 
                v-tooltip.bottom="'Strikethrough'" 
                class="ql-strike" 
              />
            </span>
          </template>
        </Editor>
      </div>

      <div class="card">
        <Editor 
          ref="conteudoEditor" 
          v-model="conteudo" 
          editor-style="height: 70px"
        >
          <template #toolbar>
            <p>Conteúdo</p>
            <span class="ql-formats">
              <button 
                v-tooltip.bottom="'Bold'" 
                class="ql-bold" 
              />
              <button 
                v-tooltip.bottom="'Italic'" 
                class="ql-italic" 
              />
              <button 
                v-tooltip.bottom="'Strikethrough'" 
                class="ql-strike" 
              />
            </span>
          </template>
        </Editor>
      </div>

      <div class="card">
        <FileUpload 
          ref="fileUploadRef" 
          accept=".jpg, .jpeg, .png" 
          :max-file-size="maxFileSize"
          file-thumbnail="Tipo de arquivo não suportado. Por favor, faça o upload de arquivos no formato .jpg, .jpeg ou .png"
          invalid-file-type-message="Tipo do arquivo invalido,certifique-se que e .jpg, .jpeg ou .png"
          invalid-file-size-message="Arquivo muito grande!"
          @select="onSelectedFiles" 
        >
          <template #header="{ chooseCallback }">
            <div class="flex flex-wrap justify-between items-center flex-1 gap-2">
              <div class="flex gap-2">
                <PrimeButton 
                  icon="pi pi-images" 
                  rounded 
                  outlined 
                  class="iconeImagem"
                  severity="secondary" 
                  @click="chooseCallback" 
                />
                <PrimeButton 
                  icon="pi pi-times" 
                  rounded 
                  outlined 
                  class="iconeX"
                  severity="danger" 
                  :disabled="!imagem"
                  @click="clear" 
                />
              </div>
              <ProgressBar 
                :value="totalSizePercent" 
                :show-value="false" 
                class="md:w-20rem h-1 w-full md:ml-auto"
              />
            </div>
          </template>
          <template #content>
            <div 
              v-if="imagem"
              class="flex flex-col items-center" 
            >
              <div class="flex flex-col items-center gap-1">
                <div class="card flex justify-center">
                  <Image 
                    role="presentation" 
                    :alt="imagem.name" 
                    :src="imagem.objectURL"
                    width="200" 
                    class="imagem" 
                    preview
                  />
                </div>
                <span class="font-semibold text-ellipsis max-w-xs whitespace-nowrap overflow-hidden">{{ imagem.name }}</span>
                <div>Tamanho do Arquivo {{ formatSize(imagem.size) }}</div>
              </div>
            </div>
          </template>
          <template #empty>
            <div class="flex items-center justify-center flex-col">
              <i class="pi pi-cloud-upload !border-2 !rounded-full !p-8 !text-4xl !text-muted-color" />
              <p class="mt-6 mb-0 text-center">
                Arraste e solte os arquivos aqui para fazer upload.<br>
                <span>
                  imagem da campanha {{ imagem || legenda ? '(obrigatório)' : '(opcional)' }}
                </span> 
              </p>
            </div>
          </template>
        </FileUpload>
      </div>

      <div class="mt-6">
        <FloatLabel>
          <inputText 
            id="legenda" 
            v-model="legenda" 
            type="text" 
            class="w-full" 
          />
          <label for="legenda">Legenda da imagem {{ imagem || legenda ? '(obrigatório)' : '(opcional)' }}</label>
        </FloatLabel>
      </div>
    </div>

    <div class="flex gap-2 justify-center">
      <PrimeButton 
        label="Criar Campanha"
        severity="success"
        @click="submitCampaign"
      />
      <PrimeButton 
        label="Cancelar"
        severity="danger"
        @click="voltar"
      />
    </div>
  </div>
</template>

<script setup>
  import { ref, defineEmits } from 'vue';
  import Editor from 'primevue/editor';
  import FileUpload from 'primevue/fileupload';
  import ProgressBar from 'primevue/progressbar';
  import Image from 'primevue/image';  
  import FloatLabel from 'primevue/floatlabel';
  import InputText from 'primevue/inputtext';  
  import { useToast } from 'primevue/usetoast';

  const toast = useToast();
  const titulo = ref('');
  const conteudo = ref('');
  const imagem = ref(null);
  const totalSizePercent = ref(0);
  const totalSize = ref(0);
  const fileUploadRef = ref(null); 
  const maxFileSize = 1048576;
  const legenda = ref('');
  const emit = defineEmits(['voltar', 'campaign-created']);

  const tituloEditor = ref(null);

  const handleTituloInput = () => {
    const maxLength = 60;
    const editor = tituloEditor.value.$el.querySelector('.ql-editor');
    if (editor.innerText.length > maxLength) {
      editor.innerText = editor.innerText.slice(0, maxLength);
      titulo.value = editor.innerText;
      toast.add({ severity: 'warn', summary: 'Atenção', detail: `O título deve ter no máximo ${maxLength} caracteres.`, life: 2000 });
    }
  };

  const clear = () => {
    imagem.value = null;  
    totalSize.value = 0;       
    totalSizePercent.value = 0;
    
    if (fileUploadRef.value && fileUploadRef.value.clear) {
      fileUploadRef.value.clear(); 
    }
  };

  const onSelectedFiles = (event) => {
    if (imagem.value) {
      toast.add({ 
        severity: 'warn', 
        summary: 'Atenção', 
        detail: 'Você só pode carregar um arquivo.',  
        life: 2000 
      });
      return;
    }

    if (event.files.length > 0) {
      const file = event.files[0];
      imagem.value = file;
      totalSize.value = file.size;
      totalSizePercent.value = 100; 
    } else {
      toast.add({ 
        severity: 'warn', 
        summary: 'Atenção', 
        detail: 'Verifique o Arquivo, ele pode não está selecionado no formato webp ou maior que 1mb.',
        life: 2000   
      });
    }
  };

  const formatSize = (bytes) => {
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB'];
    if (bytes === 0) return '0 Byte';
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  };

  const voltar = () => {
    emit('voltar');
  };

  const submitCampaign = () => {
    if (!titulo.value || !conteudo.value ||(imagem.value && !legenda.value) || (!imagem.value && legenda.value)) {
      let mensagem = 'Preencha os seguintes campos obrigatórios:\n\n';
      if (!titulo.value) mensagem += '• Título da promoção\n';
      if (!conteudo.value) mensagem += '• Conteúdo da promoção\n';
      if (imagem.value && !legenda.value) mensagem += '• Legenda da imagem\n';
      if (!imagem.value && legenda.value) mensagem += '• Imagem da promoção\n';
      toast.add({ severity: 'error', summary: 'Erro', detail: mensagem, life: 5000 });
      return;
    }

    let formData = new FormData();
    formData.append('titulo', titulo.value);
    formData.append('conteudo', conteudo.value);
    formData.append('legenda', legenda.value);

    if (imagem.value) {
      formData.append('imagem', imagem.value);
    }

    fetch(`${process.env.VUE_APP_BASE_URL}/whats/criar-campanha-whatsapp`, {
      method: 'POST',
      body: formData
    })
    .then((response) => response.json())
    .then(() => {
      const campaign = {};
      formData.forEach((value, key) => {
        if (key === 'datasAtivas') {
          campaign[key] = JSON.parse(value);
        } else {
          campaign[key] = value;
        }
      });
      toast.add({ severity: 'success', summary: 'Sucesso', detail: 'Campanha criada com sucesso', life: 3000 });
      emit('campaign-created', campaign);
    })
    .catch((error) => {
      console.error('Error:', error);
    });
  };
</script>

<style scoped>
  .dark-theme input,
  .dark-theme .selectDate {
    color: black;
  }

  .light-theme .buttonConfirm,
  .dark-theme .buttonConfirm {
    background-color: #015220;
  }
  
  .light-theme .buttonConfirm:hover,
  .dark-theme .buttonConfirm:hover {
    background-color: #01a13e;
  }
</style>
