<template>
  <div class="flex flex-col items-center justify-center min-h-full">
    <div v-if="step === 1">
      <CampaignSelection 
        @create-campaign="goToCreateCampaign" 
        @select-campaign="goToSelectCampaign" 
      />
    </div>
    <div v-if="step === 2">
      <CreateCampaign 
        class="create"
        @campaign-created= "goToSelectCampaign" 
        @voltar="voltarCreateCampaign" 
      />
    </div>
    <div v-if="step === 3">
      <SelectCampaign 
        @promocao-selecionada="handleCampaignSelected" 
        @voltarSelecao="voltarInicio" 
      />
    </div>
    <div v-if="step === 4">
      <ViewCampaign 
        :campaign="selectedCampaign" 
        @voltarSelecionarCampanha="campaignSelectedReturn" 
        @campaignDeleted="campaignDeleted" 
        @selectList="listSelect"
      />
    </div>
    <div v-if="step === 5">
      <SelectionList 
        @create-list="creatList" 
        @select-list="selectList" 
        @voltarcampaign="voltarCreateCampaign"
      />
    </div>
    <div v-if="step === 6">
      <CreateList 
        @contatos-enviados="handleDataSubmitted" 
        @voltarLista="voltarLista"
      />
    </div>
    <div v-if="step === 7">
      <SearchList 
        @voltar="voltarLista" 
        @lista-selecionada="selectedList"
      />
    </div>
    <div v-if="step === 8">
      <ViewList 
        :selected-list="list" 
        @listaExcluida="voltarBuscarListaNoBanco"
        @voltarBuscarListaNoBanco="voltarBuscarListaNoBanco" 
        @confirmacaoDelista="listaConfirmada" 
      />
    </div>
    <div v-if="step === 9">
      <DispararCampaign 
        :confirm-list="list"
        :confirm-campaign="selectedCampaign" 
        @cancel="cancelarTudo" 
        @disparadoComSucesso="cancelarTudo"
      />
    </div>
  </div>
</template>

<script setup>
  import { ref } from 'vue';
  import CampaignSelection from './DispararCampanha/CampaignSelection';
  import CreateCampaign from './DispararCampanha/campanhas/CreateCampaign';
  import SelectCampaign from './DispararCampanha/campanhas/SelectCampaign';
  import ViewCampaign from './DispararCampanha/campanhas/ViewCampaign';
  import SelectionList from './DispararCampanha/SelectionList';
  import CreateList from './DispararCampanha/contatos/ContactCreatListComponent';
  import SearchList from './DispararCampanha/contatos/ContactSearchListComponent';
  import ViewList from './DispararCampanha/contatos/ViewListComponent';
  import DispararCampaign from './DispararCampanha/SendMessages';

  const step = ref(1);
  const list = ref(null);
  const selectedCampaign = ref(null);

  const goToCreateCampaign = () => {
    step.value = 2;
  };

  const goToSelectCampaign = () => {
    step.value = 3;
  };

  const voltarCreateCampaign = () => {
    selectedCampaign.value = null;
    step.value = 1;
  };

  const creatList = () => {
    step.value = 6;
  };

  const selectList = () => {
    step.value = 7;
  };

  const voltarLista = () => {
    list.value = null;
    step.value = 5;
  };

  const listSelect = (campaign) => {
    selectedCampaign.value = campaign;
    step.value = 5;
  };

  const selectedList = (lista) => {
    list.value = lista;
    step.value = 8;
  };

  const listaConfirmada = (listaConfirmada) => {
    list.value = listaConfirmada;
    step.value = 9;
  };

  const voltarBuscarListaNoBanco = () => {
    list.value = null;
    step.value = 7;
  };

  const handleCampaignSelected = (campaign) => {
    selectedCampaign.value = campaign;
    step.value = 4;
  };

  const campaignSelectedReturn = () => {
    selectedCampaign.value = null;
    step.value = 3;
  };

  const campaignDeleted = () => {
    selectedCampaign.value = null;
    step.value = 3;
  };

  const voltarInicio = () => {
    step.value = 1;
  };

  const cancelarTudo = () => {
    list.value = null;
    selectedCampaign.value = null;
    step.value = 1;
  };

  const handleDataSubmitted = () => {
    step.value = 5;
  };
</script>


<style scoped>
  .create{
    width: 600px;
    margin:10px
  }
</style>