<script setup>

</script>

<template>
  <RouterView />
</template>


<style>

  * {
    /* Global styles */
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Arial, Helvetica, sans-serif, Calibri;
  }
  .light-theme p{
    color: black;
  }

</style>

