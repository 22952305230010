<template>
  <div class="flex items-center justify-center mt-2">
    <div>
      <div 
        v-if="listBancoSelecionada" 
        class="flex flex-col gap-2 w-full"
      >
        <Select
          v-model="selectedList"
          :options="options"
          placeholder="Selecione uma lista"
          filter
          empty-message="Sem listas disponíveis"
          empty-filter-message="listas não encontrado"
          class="w-96 mb-4 text-center font-bold cursor-pointer"
          @change="selectListBanco"
        />

        <PrimeButton 
          label="Voltar"
          severity="warn"
          @click="voltarListaOuBuscar" 
        />
      </div>

      <div 
        v-if="Object.keys(dadosBanco).length !== 0" 
        class="botoesDiv flex flex-col gap-2 items-center"
      >
        <div class="flex justify-center gap-2 items-center">
          <PrimeButton 
            label="Disparar Lista"
            severity="success"
            :disabled="isEditing || isEmailsBloqueados"  
            :class="{'opacity-50': isEditing || isEmailsBloqueados}"
            @click="dispararLista" 
          />
          
          <PrimeButton 
            severity="danger"
            :disabled="isEmailsBloqueados || isEmailsAceitos" 
            :class="{'opacity-50': isEmailsBloqueados || isEmailsAceitos}"
            label="Excluir lista"
            @click="confirmarExclusao" 
          />

          <PrimeButton 
            :disabled="isEmailsBloqueados" 
            :label="isEditing ? 'Salvar' : 'Editar lista'" 
            :severity="isEditing? 'success' : 'info'"
            :class="{'opacity-50': isEmailsBloqueados}"
            @click="toggleEditarLista" 
          />

          <PrimeButton 
            v-if="isEditing" 
            severity="warn"
            label="cancelar Edição"
            @click="toggleCancelEditarLista" 
          />
          <PrimeButton 
            v-if="!isEditing" 
            severity="warn"
            label="Selecionar outra lista"
            @click="voltarLista" 
          />
        </div>

        <div class="p-3 rounded-md flex flex-col justify-center items-center gap-2 w-full">
          <h3 class="text-center">
            Lista Selecionada: {{ dadosBanco.list }} ({{ dadosBanco.entries.length }} Emails)
          </h3>

          <div 
            v-if="isEditing" 
            class="mt-2 p-2 flex items-center gap-2 justify-center w-full"
          >
            <input 
              v-model="novoNome" 
              class="editandoInputs p-1 border rounded w-full max-w-md" 
              placeholder="Novo Nome" 
            >
            <input 
              v-model="novoEmail" 
              class="editandoInputs p-1 border rounded w-full max-w-md" 
              placeholder="Novo Email" 
            >
            <div class="flex justify-center">
              <PrimeButton
                icon="pi pi-plus"
                @click="adicionarItem" 
              />
            </div>
          </div>


          <div class="w-full">
            <ul class="w-full">
              <li 
                v-if="!dadosBanco.entries.length" 
                class="text-center text-red-500"
              >
                <strong class="text-red-600">Lista Vazia</strong>
              </li>
              <li 
                v-for="(entry, index) in dadosBanco.entries" 
                :key="index" 
                :class="{'bg-blue-100 border border-blue-500': campoEditandoIndex === index }" 
                class="flex flex-col items-center mb-2 p-2 rounded"
              >
                <div 
                  v-if="isEditing && !isEmailsAceitos" 
                  class="flex gap-2 items-center w-full"
                >
                  <input
                    v-model="entry.name"
                    class="flex-1 p-1 border rounded editandoInputs"
                    placeholder="Nome"
                    @focus="campoEditandoIndex = entry.name"
                    @blur="campoEditandoIndex = -1"
                  >
                  <input
                    v-model="entry.email"
                    class="flex-1 p-1 border rounded editandoInputs"
                    placeholder="Email"
                    @focus="campoEditandoIndex = entry.email"
                    @blur="campoEditandoIndex = -1"
                  >
                  <div class="flex justify-center bg-red-100">
                    <PrimeButton 
                      severity="danger"
                      icon="pi pi-times"
                      @click="confirmarExclusaoIndex(index)" 
                    />
                  </div>
                </div>
                <div 
                  v-else 
                  class="listaRederizada flex-col"
                >
                  <span><strong>Nome:</strong> {{ entry.name }}</span><br>
                  <span><strong>Email:</strong> {{ entry.email }}</span>
                </div>
              </li>
            </ul>
          </div>
<!-- 
          <div v-if="campoEditandoIndex !== -1" class="mt-2 text-red-500">
            {{ campoEditandoIndex }}
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { ref, computed, defineEmits, defineProps } from 'vue';
  import Select from 'primevue/select';
  import { useConfirm } from 'primevue/useconfirm';
  import { useToast } from 'primevue/usetoast';
  import 'sweetalert2/src/sweetalert2.scss';

  const emit = defineEmits([
    'file-data-updated',
    'listNameChanged',
    'voltarAddOuInputs',
    'voltarCriarOuBuscar',
    'DispararEmailManual',
    'voltandoParaBuscarOuCriar',
    'dadosBanco'
  ]);

  const toast = useToast();
  const confirm = useConfirm();
  const listBancoSelecionada = ref(true);
  const selectedList = ref(null);
  const selectedListId = ref(null); 
  const dadosBanco = ref({});
  const originalDadosBanco = ref({});
  const isEditing = ref(false);
  const novoNome = ref('');
  const novoEmail = ref('');
  const campoEditandoIndex = ref('');

  const props = defineProps({
    dados: {
      type: Array,
      required: true
    }
  });

  const options = computed(() => {
    const opts = props.dados.map(item => item.list);
    return opts;
  });

  const listIdMap = computed(() => {
    const map = {};
    props.dados.forEach(item => {
      map[item.list] = item._id; 
    });
    return map;
  });

  const isEmailsBloqueados = computed(() => dadosBanco.value.list === 'EmailsBloqueados');

  const isEmailsAceitos = computed(() => dadosBanco.value.list === 'EmailsAceitos');

  const selectListBanco = (event) => {
    const value = event.value;
    selectedListId.value = listIdMap.value[value]; 
    if (selectedListId.value) {
      fetchSelectedList(selectedListId.value); 
    } else {
      console.error('ID não encontrado para o valor selecionado:', value);
    }
  };

  const fetchSelectedList = async (id) => {
    listBancoSelecionada.value = false;
    try {
      const response = await fetch(`${process.env.VUE_APP_BASE_URL}/credit/lista-banco-crediario/${id}`);
      if (!response.ok) {
        throw new Error('Erro ao buscar dados do banco');
      }
      const data = await response.json();
      dadosBanco.value = data;
      originalDadosBanco.value = JSON.parse(JSON.stringify(data));
      isEditing.value = false;
    } catch (error) {
      console.error('Erro ao buscar dados do banco:', error);
    }
  };

  const voltarLista = () => {
    listBancoSelecionada.value = true;
    selectedList.value = null;
    selectedListId.value = null;
    dadosBanco.value = {};
  };

  const voltarListaOuBuscar = () => {
    listBancoSelecionada.value = false;
    selectedList.value = null;
    selectedListId.value = null;
    dadosBanco.value = {};
    emit('voltandoParaBuscarOuCriar');
  };

  const confirmarExclusaoIndex = (index) => {
    confirm.require({
      message: 'Você tem certeza de que deseja excluir este item?',
      header: 'Confirmação',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Sim, excluir!',
      rejectLabel: 'Não, cancelar',
      acceptClass: 'p-button-danger',
      rejectClass: 'p-button-success',
      accept: () => excluirItem(index),
    });
  };

  const excluirItem = (index) => {
    dadosBanco.value.entries.splice(index, 1);
    toast.add({ severity: 'success', summary: 'Excluído', detail: 'O item foi excluído com sucesso.', life: 3000 });
  };

  const confirmarExclusao = () => {
    confirm.require({
      message: 'Você tem certeza de que deseja excluir esta lista?',
      header: 'Confirmação',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Sim, excluir!',
      rejectLabel: 'Não, cancelar',
      acceptClass: 'p-button-danger',
      rejectClass: 'p-button-success',
      accept: excluirLista,
    });
  };

  const excluirLista = async () => {
    try {
      const response = await fetch(`${process.env.VUE_APP_BASE_URL}/credit/lista-banco-crediario/${selectedListId.value}`, {
        method: 'DELETE'
      });
      if (!response.ok) {
        throw new Error('Erro ao excluir a lista');
      }
      toast.add({ severity: 'success', summary: 'Excluído', detail: 'Lista excluída com sucesso', life: 3000 });
      voltarListaOuBuscar();
    } catch (error) {
      console.error('Erro ao excluir a lista:', error);
      toast.add({ severity: 'error', summary: 'Erro', detail: 'Erro ao excluir a lista. Por favor, tente novamente mais tarde.', life: 3000 });
    }
  };

  const toggleEditarLista = () => {
    if (isEmailsBloqueados.value) return;

    if (isEditing.value) {
      salvarLista();
    }
    isEditing.value = !isEditing.value;
  };

  const salvarLista = async () => {
    let camposVazios = false;
    dadosBanco.value.entries.forEach((entry, index) => {
      if (!entry.name) {
        entry.name = originalDadosBanco.value.entries[index].name;
        camposVazios = true;
      }
      if (!entry.email) {
        entry.email = originalDadosBanco.value.entries[index].email;
        camposVazios = true;
      }
    });

    if (camposVazios) {
      toast.add({ severity: 'error', summary: 'Erro', detail: 'Todos os campos devem ser preenchidos antes de salvar.', life: 5000 });
      return;
    }

    try {
      const response = await fetch(`${process.env.VUE_APP_BASE_URL}/credit/lista-banco-crediario/${selectedListId.value}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(dadosBanco.value)
      });
      if (!response.ok) {
        throw new Error('Erro ao salvar a lista');
      }
      toast.add({ severity: 'success', summary: 'Alterações salvas.', life: 2000 });
    } catch (error) {
      console.error('Erro ao salvar a lista:', error);
      toast.add({ severity: 'error', summary: 'Erro', detail: 'Erro ao salvar a lista. Por favor, tente novamente mais tarde.', life: 5000 });
    }
    campoEditandoIndex.value = '';
  };

  const toggleCancelEditarLista = () => {
    confirm.require({
      message: 'Você tem certeza que deseja cancelar as edições? Todas as mudanças serão perdidas.',
      header: 'Confirmação',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Sim, cancelar!',
      rejectLabel: 'Não, continuar editando',
      acceptClass: 'p-button-danger',
      rejectClass: 'p-button-text',
      accept: () => {
        dadosBanco.value = JSON.parse(JSON.stringify(originalDadosBanco.value));
        isEditing.value = false;
        campoEditandoIndex.value = -1;
        toast.add({ severity: 'info', summary: 'Edições canceladas', detail: 'As mudanças foram descartadas.', life: 3000 });
      },
    });
  };

  const adicionarItem = async () => {
    let mensagemErro = '';

    if (!novoNome.value) {
      mensagemErro = 'Por favor, preencha o campo do nome.';
    } else if (!novoEmail.value) {
      mensagemErro = 'Por favor, preencha o campo do email.';
    } else if (!validarEmail(novoEmail.value)) {
      mensagemErro = 'Por favor, insira um email válido.';
    }

    if (mensagemErro) {
      toast.add({ severity: 'info', summary: `${mensagemErro}`, life: 2000 });
    } else {
      toast.add({ severity: 'success', summary: `Email adicionado`, life: 2000 });
      dadosBanco.value.entries.push({ name: novoNome.value, email: novoEmail.value });
      novoNome.value = '';
      novoEmail.value = '';
    }
  };

  const dispararLista = () => {
    if (isEditing.value || isEmailsBloqueados.value) return;
    emit('dadosBanco', dadosBanco.value);
  };

  const validarEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };
</script>

<style>
  .botoesDiv {
    width: 800px;
  }
  
  .listaRederizada {
    width: 600px;
  }
  
  .dark-theme .editandoInputs {
    color: black;
  }
</style>
