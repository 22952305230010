<template>
  <div class="users w-full flex gap-10">
    <div>
      <h2>Usuarios Cadastrados</h2>
    </div>
    <div class="usuarios">
      <ul v-if="users.length > 0">
        <li v-for="(user, index) in users" :key="index">
          <span>{{ user.name }}</span>
          <button @click="deleteUser(user)">Excluir</button>
        </li>
      </ul>
      <p v-else-if="error">{{ error }}</p>
      <p v-else>Nenhum usuário encontrado.</p>
    </div>
  </div>
</template>


<script>
  export default {
    name:'viewUsuariosCadastrados',
    data() {
      return {
        users: [],
        loading: true,
        error: null
      };
    },
    mounted() {
      this.fetchUsers();
    },
    methods: {
      async fetchUsers() {
        try {
          const response = await fetch(`${process.env.VUE_APP_BASE_URL}/searchUsers`, this.$includeAuthHeaders());

          if (!response.ok) {
            throw new Error('Erro ao buscar usuários');
          }
          const data = await response.json();
          this.users = data || [];
          this.loading = false;
        } catch (error) {
          this.error = 'Erro ao buscar usuários. Por favor, tente novamente mais tarde.';
          this.users = [];
          this.loading = false;
        }
      },

      async deleteUser(userid) {
        try {
          const response = await fetch(`${process.env.VUE_APP_BASE_URL}/deleteUser/${userid._id}`, {
            method: 'DELETE'
          });
          if (!response.ok) {
            throw new Error('Erro ao excluir usuário');
          }
          this.users = this.users.filter(user => user._id !== userid._id);
          alert(`Usuário ${userid.name} excluído com sucesso!`);
        } catch (error) {
          this.error = 'Erro ao excluir usuário. Por favor, tente novamente mais tarde.';
        }
      }
    },
  };
</script>

<style scoped>
  .users {
    flex-direction: column;
    padding: 20px;
  }

  .light-theme .users h2 {
    font-size: 24px;
  }

  .light-theme .usuarios li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 3px;
    padding: 10px;
    background-color: #038D38;
    border-radius: 5px;
  }
  
  .light-theme .usuarios li button {
    background-color: #cf0217;
    color: #fff;
    border: none;
    padding: 5px 10px;
    border-radius: 3px;
    cursor: pointer;
  }
  
  .dark-theme .usuarios li button:hover {
    background-color: #fa0019;
  }

  .dark-theme .users h2 {
    font-size: 24px;
  }

  .dark-theme .usuarios li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 3px;
    padding: 10px;
    background-color: #015220;
    border-radius: 5px;
  }
  
  .dark-theme .usuarios li button {
    background-color: #920110;
    color: #fff;
    border: none;
    padding: 5px 10px;
    border-radius: 3px;
    cursor: pointer;
  }
  
  .dark-theme .usuarios li button:hover {
    background-color: #b30214;
  }

</style>
