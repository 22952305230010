<template>
  <main 
    class="fixed-container bg-green-600 h-full flex justify-center items-center"
  >
    <div 
      class="flex flex-col items-center py-8 px-4 rounded-xl bg-white shadow-lg mobile:px-2 mobile:py-4"
    >
      <div 
        class="flex items-center p-10 mobile:p-4"
      >
        <div 
          class="flex flex-col gap-2 p-12 shadow-lg focus:outline-none mobile:p-6 animate-drop-in custom-shadow"
        >
          <h2 
            class="font-bold text-lg"
          >
            Marketing
            <span class="dots animate-pulse" />
          </h2>

          <form 
            class="w-full max-w-md flex flex-col gap-2" 
            @submit.prevent="login"
          >
            <div 
              class="flex items-center relative mt-2"
            >
              <input
                id="email"
                v-model="email"
                type="text"
                class="border-2 p-2 w-full rounded-sm text-lg"
                placeholder=""
                autocomplete="email"
                required
              >
              <label
                for="email"
                class="absolute left-2 top-1/2 transform -translate-y-1/2 transition-all duration-200 ease-in-out bg-white px-1 text-gray-500"
              >
                E-mail
              </label>
            </div>
            <div 
              v-if="emailError" 
              class="text-red-500 mb-2"
            >
              {{ emailError }}
            </div>

            <div 
              class="flex items-center relative mt-2"
            >
              <input 
                id="password" 
                v-model="password" 
                :type="isPasswordVisible ? 'text' : 'password'" 
                class="border-2 p-2 rounded-sm w-full text-lg" 
                placeholder="" 
                autocomplete="current-password" 
                required 
              >
              <label 
                for="password" 
                class="absolute left-2 top-1/2 transform -translate-y-1/2 transition-all duration-200 ease-in-out bg-white px-1 text-gray-500"
              >
                Senha
              </label>
              <i
                :class="isPasswordVisible ? 'pi pi-eye' : 'pi pi-eye-slash'"
                class="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer"
                @click="togglePasswordVisibility"
              />
            </div>
            <div 
              v-if="responseData" 
              class="text-red-500"
            >
              {{ responseData }}
            </div>
            <button 
              type="submit" 
              class="bg-green-600 text-white py-2 px-8 rounded-md w-full hover:bg-green-700 transition-colors duration-300"
            >
              Entrar
            </button>
          </form>
        </div>
        <div 
          class="hidden sm:block"
        >
          <img 
            src="/imgs/login/mascote 02.png" 
            class="w-64 h-64 mobile:w-40 mobile:h-40" 
            alt="Mascote Super Lojas Nosso Lar" 
          >
        </div>
      </div>
      <p class="text-center mt-8 text-sm transition-opacity duration-500 ease-in-out opacity-50 hover:opacity-100 mobile:mt-4">
        Desenvolvido por Super Lojas Nosso Lar
      </p>
    </div>
  </main>
</template>

<script setup>
  import { ref } from 'vue';
  import { useRouter } from 'vue-router';
  import { useStore } from 'vuex';
  import Cookies from 'js-cookie';

  const email = ref('');
  const password = ref('');
  const responseData = ref('');
  const emailError = ref('');

  const isPasswordVisible = ref(false);

  const router = useRouter();
  const store = useStore();

  const togglePasswordVisibility = () => {
    isPasswordVisible.value = !isPasswordVisible.value;
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[a-zA-Z]{2,24}$/;
    return re.test(String(email).toLowerCase());
  };


  const login = async () => {
    emailError.value = '';
    responseData.value = '';

    if (!validateEmail(email.value)) {
      emailError.value = 'Por favor, insira um e-mail válido.';
      return;
    }

    try {
      const response = await fetch(`${process.env.VUE_APP_BASE_URL}/signin`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: email.value,
          password: password.value,
        }),
      });

      let responseDataContent;
      const contentType = response.headers.get('content-type');

      if (contentType && contentType.indexOf('application/json') !== -1) {
        responseDataContent = await response.json();
        
      } else {
        responseDataContent = await response.text();
      }
      if (response.ok) {
        if (typeof responseDataContent === 'string') {
          const expirationDate = new Date();
          expirationDate.setDate(expirationDate.getDate() + 1);
          Cookies.set('token', responseDataContent, { expires: expirationDate });
          store.commit('setUserToken', responseDataContent);
          const userResponse = await fetch(`${process.env.VUE_APP_BASE_URL}/user`, {
            headers: {
              'Authorization': `Bearer ${responseDataContent}`
            }
          });
          const userData = await userResponse.json();
          store.commit('setUserData', userData);
        }
        router.push({ name: 'home' });
      } else {
        if (typeof responseDataContent === 'string') {
          if (responseDataContent.toLowerCase().includes('email')) {
            emailError.value = responseDataContent;
          } else if (responseDataContent.toLowerCase().includes('senha')) {
            responseData.value = responseDataContent;
          }
        } else if (Array.isArray(responseDataContent)) {
          responseDataContent.forEach(error => {
            if (error.toLowerCase().includes('email')) {
              emailError.value = error;
            } else if (error.toLowerCase().includes('senha')) {
              responseData.value = error;
            }
          });
        } else if (responseDataContent && typeof responseDataContent.error === 'string') {
          if (responseDataContent.error.toLowerCase().includes('email')) {
            emailError.value = responseDataContent.error;
          } else if (responseDataContent.error.toLowerCase().includes('senha')) {
            responseData.value = responseDataContent.error;
          }
        }
      }
    } catch (error) {
      console.error('Erro durante a autenticação:', error);
      responseData.value = 'Erro ao tentar fazer login. Por favor, tente novamente.';
    }
  };
</script>

<style scoped>
  .fixed-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .grupos img {
    width: 18px;
    height: 18px;
  }

  @keyframes drop-in {
    0% {
      transform: translateY(-50px); 
      opacity: 0; 
    }
    100% {
      transform: translateY(0); 
      opacity: 1; 
    }
  }

  .custom-shadow {
    box-shadow: 
      0 -8px 16px rgba(0, 0, 0, 0.2), 
      0 8px 16px rgba(0, 0, 0, 0.3), 
      0 16px 32px rgba(0, 0, 0, 0.1); 
  }

  .animate-drop-in {
    animation: drop-in 0.5s ease-out;
  }

  @keyframes typing {
    0% {
      content: "";
    }
    25% {
      content: ".";
    }
    50% {
      content: "..";
    }
    75% {
      content: "...";
    }
  }

  .light-theme h2{
    color: green;
  }

  .dots::after {
    content: "";
    display: inline-block;
    animation: typing 1s steps(4) infinite;
  }

  .relative input:not(:placeholder-shown) + label,
  .relative input:focus + label {
    top: -12px;
    left: 8px;
    border-radius: 10px;
    font-size: 0.8rem;
    color: green;
    font-weight: bolder;
    transform: none; 
  }

  .relative input:focus {
    border-color: green;
    outline: none; 
  }

  .relative label {
    pointer-events: none;
    transition: all 0.2s ease-in-out;
  }
</style>
