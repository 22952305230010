<template>
  <div class="flex flex-col items-center justify-center">
    <div class="w-full max-w-lg p-6">
      <Select 
        v-model="templateToDelete" 
        :options="templates" 
        show-clear
        option-label="name"
        placeholder="Selecione um Template para excluir"
        empty-message="Sem Templates disponíveis"
        empty-filter-message="Template não encontrado"
        filter
        filter-placeholder="Filtrar templates"
        class="w-full mb-4 text-center font-bold cursor-pointer"
      >
        <template #value="slotProps">
          <div 
            v-if="slotProps.value" 
            class="flex items-center"
          >
            <img 
              src="https://primefaces.org/cdn/primevue/images/flag/flag_placeholder.png" 
              :alt="slotProps.value.name" 
              class="mr-2" 
              style="width: 18px" 
            >
            <div>{{ slotProps.value.name }}</div>
          </div>
          <span v-else>
            {{ slotProps.placeholder }}
          </span>
        </template>
        <template #option="slotProps">
          <div class="flex items-center">
            <img 
              :alt="slotProps.option.name" 
              src="https://primefaces.org/cdn/primevue/images/flag/flag_placeholder.png" 
              class="mr-2" 
              style="width: 18px" 
            >
            <div>{{ slotProps.option.name }}</div>
          </div>
        </template>
      </Select>

      <div class="flex flex-col sm:flex-row gap-2 justify-center w-full max-w-3xl">
        <div>
          <ConfirmDialog 
            group="headless"
          >
            <template 
              #container="{ message, acceptCallback, rejectCallback }"
            >
              <div 
                class="flex flex-col items-center p-8 bg-surface-0 dark:bg-surface-900 rounded"
              >
                <span 
                  class="font-bold text-2xl block mb-2 mt-6"
                >
                  {{ message.header }}
                </span>
                <p 
                  class="mb-0"
                >
                  {{ message.message }}
                </p>
                <div class="flex items-center gap-2 mt-6">
                  <PrimeButton 
                    label="Excluir" 
                    severity="danger"
                    @click="acceptCallback" 
                  />
                  <PrimeButton 
                    label="Cancelar" 
                    severity="warn"
                    @click="rejectCallback"
                  />
                </div>
              </div>
            </template>
          </ConfirmDialog>
        
          <div class="card flex justify-center">
            <PrimeButton 
              type="button" 
              severity="danger" 
              label="Excluir Template" 
              :disabled="!templateToDelete"
              @click="requireConfirmation" 
            />
          </div>
        </div>

        <PrimeButton 
          severity="warn"
          label="Cancelar"
          @click="cancelDelete" 
        />
      </div>
    </div>
  </div>
</template>

<script setup>
  import { ref, onMounted, defineEmits } from 'vue';
  import { useToast } from 'primevue/usetoast';
  import { useConfirm } from "primevue/useconfirm";
  import ConfirmDialog from 'primevue/confirmdialog';
  import Select from 'primevue/select';

  const emit = defineEmits(['cancel']);
  const toast = useToast();
  const confirm = useConfirm();
  
  const templates = ref([]);
  const templateToDelete = ref(null);

  const fetchTemplates = async () => {
    try {
      const response = await fetch(`${process.env.VUE_APP_BASE_URL}/credit/templateEmails-crediario`);
      const data = await response.json();
      templates.value = data.templates;
    } catch (error) {
      console.error('Erro ao obter os dados dos templates:', error);
    }
  };

  const requireConfirmation = () => {
    confirm.require({
      group: 'headless',
      header: 'Tem certeza?',
      message: 'Para Confirmar a exclusão clique em "EXCLUIR" caso contrario clique em "CANCELAR"',
      accept: () => {
        confirmDelete()
      },
    });
  };

  const confirmDelete = async () => {
    try {
      const response = await fetch(`${process.env.VUE_APP_BASE_URL}/credit/templateEmails-crediario/${templateToDelete.value.id}`, {
        method: 'DELETE'
      });

      if (response.ok) {
        toast.add({ 
          severity: 'success', 
          summary: 'Excluído!', 
          detail: 'Template excluído com sucesso', 
          life: 3000 
        });
        templateToDelete.value = null;
        fetchTemplates();
      } else {
        toast.add({ 
          severity: 'error', 
          summary: 'Erro', 
          detail: 'Erro ao excluir o template', 
          life: 3000 
        });
      }
    } catch (error) {
      console.error('Erro ao excluir o template:', error);
      toast.add({ 
        severity: 'error', 
        summary: 'Erro', 
        detail: 'Erro ao excluir o template. Por favor, tente novamente mais tarde.', 
        life: 3000 
      });
    }
  };

  const cancelDelete = () => {
    emit('cancel')
  };

  onMounted(fetchTemplates);
</script>

<style scoped>
  select {
    max-width: 100%;
  }
  option {
    padding: 10px; 
    width: 100%; 
    white-space: nowrap; 
  }
</style>