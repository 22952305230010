<template>
  <div class="items-center justify-center">
    <h2 class="font-bold  text-center text-lg sm:text-xl md:text-2xl lg:text-3xl break-words">
      Crediario
    </h2>
    <p class="p-2 font-bold">
      Selecione o Ano e o Mês da promoção
    </p>
    <div class="flex gap-2 sm:flex-row sm:space-x-4 mb-4 w-full items-center justify-center">
      <div class="select-wrapper">
        <div class="custom-select">
          <div 
            class="select-box" 
            @click="toggleMonthDropdown"
          >
            {{ selectedMonthLabel }}
          </div>
          <ul 
            v-show="monthDropdownOpen" 
            class="select-options"
          >
            <li 
              v-for="(month, index) in months" 
              :key="index" 
              class="optionAno" 
              @click="selectMonth(index + 1)"
            >
              {{ month }}
            </li>
          </ul>
        </div>
      </div>
      <div class="select-wrapper">
        <div class="custom-select">
          <div 
            class="select-box" 
            @click="toggleYearDropdown"
          >
            {{ selectedYear ? selectedYear : 'Selecione o Ano' }}
          </div>
          <ul 
            v-show="yearDropdownOpen" 
            class="select-options"
          >
            <li 
              v-for="year in availableYears" 
              :key="year" 
              class="optionAno" 
              @click="selectYear(year)"
            >
              {{ year }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { ref, computed, defineProps, defineEmits } from 'vue';

  const props = defineProps({
    months: Array,
    availableYears: Array,
    selectedMonth: Number,
    selectedYear: Number
  });

  const emit = defineEmits(['month-change', 'year-change']);
  const monthDropdownOpen = ref(false);
  const yearDropdownOpen = ref(false);

  const selectedMonthLabel = computed(() => {
    return props.selectedMonth ? props.months[props.selectedMonth - 1] : 'Selecione o Mês';
  });

  const toggleMonthDropdown = () => {
    monthDropdownOpen.value = !monthDropdownOpen.value;
    yearDropdownOpen.value = false;
  };

  const toggleYearDropdown = () => {
    yearDropdownOpen.value = !yearDropdownOpen.value;
    monthDropdownOpen.value = false;
  };

  const selectMonth = (month) => {
    emit('month-change', month);
    monthDropdownOpen.value = false;
  };

  const selectYear = (year) => {
    emit('year-change', year);
    yearDropdownOpen.value = false;
  };
</script>

<style scoped>
  .select-wrapper {
    position: relative;
    width: 100%;
    max-width: 250px;
  }

  .custom-select {
    position: relative;
    width: 100%;
  }

  .select-box {
    padding: 10px;
    border: 1px solid #d1d5db;
    border-radius: 5px;
    background-color: #fff;
    cursor: pointer;
    text-align: center;
  }

  .select-options {
    list-style: none;
    padding: 0;
    margin: 0;
    border: 1px solid #d1d5db;
    border-radius: 5px;
    background-color: #fff;
    position: absolute;
    width: 100%;
    max-height: 150px;
    overflow-y: auto;
    z-index: 10;
  }

  .optionAno {
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  .optionAno:hover {
    background-color: #2f855a; 
    color: #fff;
  }
  .select {
    width: 100%;
    padding: 10px;
    border: 1px solid #d1d5db;
    border-radius: 5px;
  }

  .select:focus {
    outline: none;
    border-color: #4a5568;
    box-shadow: 0 0 0 2px rgba(66, 153, 225, 0.6);
  }
</style>
