<template>
  <div 
    :class="['info-box rounded-lg overflow-hidden shadow-lg transform transition-transform duration-500 cursor-pointer border-2', borderColorClass]"
    @click="navigateTo" 
  >
    <div 
      class="p-6 flex flex-col justify-center flex-grow"
    >
      <h2
        class="text-2xl font-bold mb-4"
      >
        {{ currentTitle }}
      </h2>
      <p>{{ currentDescription }}</p>
      <div 
        :class="[currentGradientClass, 'px-6 py-4', 'mt-4']"
      />
    </div>
  </div>
</template>

<script setup>
  import { ref, computed, onMounted, defineProps } from 'vue';
  import { useRouter } from 'vue-router';

  const props = defineProps({
    title: {
      type: [String, Array],
      default: '',
    },
    description: {
      type: [String, Array],
      default: '',
    },
    gradient: {
      type: [String, Array],
      default: '',
    },
    borderColor: {
      type: [String, Array],
      default: '',
    },
    link: {
      type: [String, Object],
      default: '',
    },
    interval: {
      type: Number,
      default: 3000,
    },
  });

  const router = useRouter();

  const currentIndex = ref(0);

  const currentTitle = computed(() =>
    Array.isArray(props.title) ? props.title[currentIndex.value] : props.title
  );

  const currentDescription = computed(() =>
    Array.isArray(props.description) ? props.description[currentIndex.value] : props.description
  );

  const borderColorClass = computed(() =>
    Array.isArray(props.borderColor) ? props.borderColor[currentIndex.value] : props.borderColor
  );

  const currentGradientClass = computed(() =>
    Array.isArray(props.gradient) ? props.gradient[currentIndex.value] : props.gradient
  );

  const navigateTo = () => {
    if (typeof props.link === 'object' && props.link.name) {
      router.push({ name: props.link.name });
    } else if (typeof props.link === 'string') {
      window.open(props.link, '_blank');
    }
  };

  const updateContent = () => {
    currentIndex.value = (currentIndex.value + 1) % (Array.isArray(props.title) ? props.title.length : 1);
  };

  onMounted(() => {
    if (Array.isArray(props.title) && props.title.length > 1) {
      setInterval(updateContent, props.interval);
    }
  });
</script>

<style scoped>
  @keyframes fadeIn {
    0% { opacity: 0; transform: translateY(10px); }
    100% { opacity: 1; transform: translateY(0); }
  }

  .light-theme .info-box {
    transition: all 0.3s ease;
    animation: fadeIn 0.5s ease forwards;
  }

  .light-theme .info-box:hover {

    transform: translateY(-10px) scale(1.05);
  }

  .light-theme .info-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  .dark-theme .info-box {
    transition: all 0.3s ease;
    animation: fadeIn 0.5s ease forwards;
  }

  .dark-theme .info-box:hover {
    transform: translateY(-10px) scale(1.05);
  }

  .dark-theme .info-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  .dark-theme p{
    color: white;
  }

</style>
